import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"

export const pendingOrdersCountApi = createAsyncThunk(
    'orders/detail',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(`/orders/count/pending`, { params: data })
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const pendingOrdersCountSlice = createSlice({
    name: 'detail',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(pendingOrdersCountApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(pendingOrdersCountApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(pendingOrdersCountApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const pendingOrdersCountReducer = pendingOrdersCountSlice.reducer