import React from 'react'
import Appbar from '../../components/Appbar'
import Drawer from '../../components/Drawer'
import Body from '../../components/Body'
import { Box, Paper, Stack, Typography } from '@mui/material'
import InventoryIcon from '@mui/icons-material/Inventory';
import { DataGrid } from '@mui/x-data-grid'
import NodataImg from "../../assets/img/noData.png"

export default function DispatchStatus({first_name}) {

    const columns = [
        {
            field: 'order_id',
            headerName: 'Order ID',
            width: 200
        },
        {
            field: 'supplier',
            headerName: 'PO Date',
            width: 200
        },
        {
            field: 'product',
            headerName: 'Dispatch Date',
            width: 200
        },
        {
            field: 'order_date',
            headerName: 'ETA',
            width: 200
        },
        {
            field: 'eta',
            headerName: 'Details',
            width: 200
        },

    ]

    return (
        <>
            {/* <Appbar />
            <Drawer /> */}
            <Body>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Box className='divider1'></Box>
                    <Typography variant='h6'>{`Welcome ${first_name}`}</Typography>
                </Stack>
                <Stack direction='row' spacing={1} sx={{ my: 2 }} alignItems='center'>
                    <InventoryIcon fontSize='large' />
                    <Typography>View Dispatch Status</Typography>
                </Stack>
                <Stack>

                </Stack>

                <Paper elevation={0}>
                    <DataGrid
                        sx={{ maxHeight: 500 }}
                        rows={[]}
                        columns={columns}
                        disableRowSelectionOnClick
                        slots={{
                            noRowsOverlay: () => <Box className='noData'><img src={NodataImg} alt='no data found image' /></Box>
                        }}
                        pagination
                        rowsPerPageOptions={[25, 50, 100]}
                    />
                </Paper>
            </Body>
        </>
    )
}
