import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"

// Define the async thunk for setting the revised layout view data
export const postRevisedLayoutView = createAsyncThunk(
    'layout/revised-layout-view',
    async (data) => {
        return { data, message: "Data successfully set" };
    }
)

// Define the action for resetting the revised view layout
export const resetRevisedViewLayout = createAction('layout/reset-revised-layout-view')

// Create the slice for handling the state
const revisedLayoutViewSlice = createSlice({
    name: 'revised-layout-view',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(postRevisedLayoutView.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(postRevisedLayoutView.fulfilled, (state, action) => {
            state.loading = false;
            state.result = action.payload.data;
            state.message = action.payload.message;
        });
        builder.addCase(postRevisedLayoutView.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
            state.message = action.error.message;
        });
        builder.addCase(resetRevisedViewLayout, (state) => {
            state.result = [];
            state.loading = false;
            state.error = null;
            state.message = "";
        });
    },
});

// Export the reducer
export const revisedLayoutViewReducer = revisedLayoutViewSlice.reducer;
