import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthAxios from '../../config/authAxios'; 

export const getCitiesAPI = createAsyncThunk(
    'cities/get-cities', 
    async (_, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get('/api/v1/dealor/cities/', {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },           
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

const getCitiesSlice = createSlice({
    name: 'get-cities',
    initialState: { result: [], loading: false, error: null, message: "" },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCitiesAPI.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getCitiesAPI.fulfilled, (state, action) => {
            state.loading = false;
            state.result = action.payload;
            state.error = null;
        });
        builder.addCase(getCitiesAPI.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    }
});

export const getCitiesReducer = getCitiesSlice.reducer;