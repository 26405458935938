import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import Appbar from './Appbar';
import Drawer from './Drawer';

const ProtectedLayout = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state?.login?.result?.email);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);

  if (!isLoggedIn) {
    return null;
  }

  return (
    <div>
      <Appbar />
      <Drawer />
      <Outlet />
    </div>
  );
};

export default ProtectedLayout;
