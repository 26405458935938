export const outletType = [
    {
        'id':1,
        'outlet_type':"Main Dealer"
    },
    {
        'id':2,
        'outlet_type':"ECO"
    },
    {
        'id':3,
        'outlet_type':"1S"
    },
    {
        'id':4,
        'outlet_type':"2S"
    },
    {
        'id':5,
        'outlet_type':"CPO"
    },
    {
        'id':6,
        'outlet_type':"3S"
    }

]

export const subOType = [

    {
        "id": 1,
        "facility_type": "3S",
        "outlet_t": 1
    },
    {
        "id": 2,
        "facility_type": "1S+2S",
        "outlet_t": 1
    },
    {
        "id": 3,
        "facility_type": "3S",
        "outlet_t": 2
    },
    {
        "id": 5,
        "facility_type": "1S",
        "outlet_t": 2
    },
    {
        "id": 11,
        "facility_type": "1S+2S",
        "outlet_t": 2
    },
    {
        "id": 11,
        "facility_type": "ECO",
        "outlet_t": 2
    },
    {
        "id": 11,
        "facility_type": "3S",
        "outlet_t": 2
    },
    {
        "id": 12,
        "facility_type": "i2s",
        "outlet_t": 2
    },
    {
        "id": 6,
        "facility_type": "1S",
        "outlet_t": 3
    },
    {
        "id": 8,
        "facility_type": "Mech + BP",
        "outlet_t": 4
    },
    {
        "id": 9,
        "facility_type": "Mech",
        "outlet_t": 4
    },
    {
        "id": 10,
        "facility_type": "BP",
        "outlet_t": 4
    },
    {
        "id": 7,
        "facility_type": "CPO",
        "outlet_t": 5
    },
    {
        "id": 8,
        "facility_type": "CPO",
        "outlet_t": 6
    }



    // {   
    //     'parent_id':1,
    //     'parent_outlet':"Main Dealer",
    //     'id':1,
    //     'outlet_type':"3S"
    // },
    // {   
    //     'parent_id':1,
    //     'parent_outlet':"Main Dealer",
    //     'id':2,
    //     'outlet_type':"1S+2S"
    // },
    // {   
    //     'parent_id':2,
    //     'parent_outlet':"ECO",
    //     'id':3,
    //     'outlet_type':"3S"
    // },
    // {   
    //     'parent_id':2,
    //     'parent_outlet':"ECO",
    //     'id':4,
    //     'outlet_type':"1S+2S"
    // },
    // {   
    //     'parent_id':2,
    //     'parent_outlet':"ECO",
    //     'id':4,
    //     'outlet_type':"1S"
    // },
    // {   
    //     'parent_id':3,
    //     'parent_outlet':"1S",
    //     'id':5,
    //     'outlet_type':"1S"
    // },
    // {   
    //     'parent_id':4,
    //     'parent_outlet':"2S",
    //     'id':6,
    //     'outlet_type':"Mech+BP"
    // },
    // {   
    //     'parent_id':4,
    //     'parent_outlet':"2S",
    //     'id':7,
    //     'outlet_type':"Mech"
    // },
    // {   
    //     'parent_id':4,
    //     'parent_outlet':"2S",
    //     'id':8,
    //     'outlet_type':"BP"
    // },

    // {   
    //     'parent_id':5,
    //     'parent_outlet':"CPO",
    //     'id':9,
    //     'outlet_type':"CPO"
    // }
    

]