import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { getDesignEstimate } from '../../store/home/designEstimate';
import { convertToFormData } from '../ConvertToFormData';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function DesignEstimate() {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const formId = useSelector((state) => state?.inputDrawingByClusterReducer?.result);
    const [open, setOpen] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [placement, setPlacement] = useState();

    const handleClickPopper = (newPlacement, index) => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => ({
            ...prev,
            [index]: !prev[index]
        }));
        setPlacement(newPlacement);
    };

    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;

    const designEstimate = async () => {
        const formD = {
            'form_id': formId?.data?.id
        };
        const data = convertToFormData(formD);
        try {
            const response = await dispatch(getDesignEstimate(data));
            setData(response?.payload?.data);
        } catch (error) {
            
        }
    };

    useEffect(() => {
        designEstimate();
    }, []);

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Typography variant="h6" className="fs-17  fw-400">Design Estimate</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                    <TableContainer component={Paper} sx={{ borderRadius: "10px 10px 0 0" }}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell sx={{ background: "#85949F !important" }}>Estimate Type</StyledTableCell>
                                    <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Date</StyledTableCell>
                                    <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Payment Status</StyledTableCell>
                                    <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Payment Slip</StyledTableCell>
                                    <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Invoice</StyledTableCell>    
                                    <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.map((row, index) => (
                                    <StyledTableRow key={row?.name}>
                                        <StyledTableCell component="th" scope="row">{row.estimate_type}</StyledTableCell>
                                        <StyledTableCell align="center">{row?.created_at.split("T")[0]}</StyledTableCell>
                                        <StyledTableCell align="center">
                                            {row?.payment_status === false ? (
                                                <Button className="notdoneBtn">Not Done</Button>
                                            ) : (
                                                <Button className="doneBtn">Done</Button>
                                            )}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {row.payment_slip === null ? (
                                                <Button className="notdoneBtn">Request</Button>
                                            ) : (
                                                <Button className="DownloadBtn" onClick={handleClickPopper('right', index)}>View</Button>
                                            )}
                                            <Popper id={id} open={open[index]} anchorEl={anchorEl} transition placement={placement}>
                                                {({ TransitionProps }) => (
                                                    <Fade {...TransitionProps} timeout={350}>
                                                        <Box
                                                            sx={{
                                                                border: '1px solid',
                                                                borderColor: 'grey.300',
                                                                boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
                                                                p: 1,
                                                                bgcolor: 'background.paper',
                                                                borderRadius: 0,
                                                                width: 300,
                                                                height: 300,
                                                            }}
                                                        >
                                                            <p>UTR No. {row?.utr_no}</p>
                                                            {row?.payment_slip === null ?
                                                                <p>Payment not done</p> :
                                                                <img src={`${process.env.REACT_APP_BASE_URL}${row?.payment_slip}`} alt="Payment Slip" height={280} width={300} />}
                                                        </Box>
                                                    </Fade>
                                                )}
                                            </Popper>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {row.Invoice === 1 ? (
                                                <Button className="notdoneBtn">Request</Button>
                                            ) : (
                                                <Button className="DownloadBtn">View</Button>
                                            )}
                                        </StyledTableCell>
                                      
                                        <StyledTableCell align="center">
                                            <Button className="DownloadBtn">Download</Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );
}
