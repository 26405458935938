import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthAxios from '../../config/authAxios';

export const getUserTypeApi = createAsyncThunk(
    'rolesAccessManagement/getUserType',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(`/api/v1/login/user_type/`,
                { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") } }
            )
            return response?.data
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

const getUserTypeSlice = createSlice({
    name: 'get-role',
    initialState: { result: [], loading: false, error: null, message: "" },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUserTypeApi.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getUserTypeApi.fulfilled, (state, action) => {
            state.loading = false;
            state.userTypeData = action.payload;
            state.error = null;
        });
        builder.addCase(getUserTypeApi.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

    }
});

export const getUserTypeReducer = getUserTypeSlice.reducer;
