import CameraAltIcon from "@mui/icons-material/CameraAlt";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PersonIcon from "@mui/icons-material/Person";
import {
  Badge,
  Button,
  Card,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DefaultProfileImg from "../../assets/img/defaultProfileImg.png";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import Style from "../../styles/profile.module.scss";
import LockIcon from "@mui/icons-material/Lock";
import { getApplicantsApi } from "../../store/dealorOnBoarding/getApplicant";
import { useEffect } from "react";

export default function DealorProfile() {
  const currentDealorSelector = useSelector((state) => state?.currentDealor);
  const {
    result: { first_name, last_name, mobile_number },
  } = currentDealorSelector;

  const [accountToggle, setAccountToggle] = useState("accountToggle");

  const handleAccountSettingToggle = (val) => {
    setAccountToggle(val);
  };
  const dispatch = useDispatch();
  useEffect(() => {
   
    dispatch(getApplicantsApi());
  }, []);

  return (
    <>
      {/* <Appbar />
      <Drawer /> */}
      <Body>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Card className={Style?.profileSection}>
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={
                  <IconButton>
                    <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id="raised-button-file"
                      multiple
                      type="file"
                    />
                    <label htmlFor="raised-button-file">
                      <CameraAltIcon />
                    </label>
                  </IconButton>
                }
              >
                <img src={DefaultProfileImg} alt="profile image" />
              </Badge>

              <Typography fontWeight={600}>
                {first_name + " " + last_name}
              </Typography>
              <Typography>Dealor</Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={8}>
            <Card>
              <Button
                variant={accountToggle == "accountToggle" && "contained"}
                onClick={() => handleAccountSettingToggle("accountToggle")}
              >
                Account Setting
              </Button>
              <Button
                variant={accountToggle == "passwordToggle" && "contained"}
                onClick={() => handleAccountSettingToggle("passwordToggle")}
              >
                Change Password
              </Button>
              <Stack spacing={2} my={2}>
                <Divider />

                {accountToggle == "accountToggle" && (
                  <>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email">
                          First Name
                        </InputLabel>
                        <OutlinedInput
                          label="First Name"
                          id="outlined-adornment-email"
                          startAdornment={<PersonIcon />}
                          defaultValue={first_name}
                        />
                        <FormHelperText error></FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email">
                          Last Name
                        </InputLabel>
                        <OutlinedInput
                          label="Last Name"
                          id="outlined-adornment-email"
                          startAdornment={<PersonIcon />}
                          defaultValue={last_name}
                        />
                        <FormHelperText error></FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email">
                          Email
                        </InputLabel>
                        <OutlinedInput
                          label="Email"
                          id="outlined-adornment-email"
                          startAdornment={<EmailIcon />}
                        />
                        <FormHelperText error></FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-email">
                          Phone Number
                        </InputLabel>
                        <OutlinedInput
                          label="Phone Number"
                          id="outlined-adornment-email"
                          startAdornment={<LocalPhoneIcon />}
                          defaultValue={mobile_number}
                        />
                        <FormHelperText error></FormHelperText>
                      </FormControl>
                    </Stack>
                  </>
                )}

                {accountToggle == "passwordToggle" && (
                  <>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-newPassword">
                          New Password
                        </InputLabel>
                        <OutlinedInput
                          label="New Password"
                          id="outlined-adornment-newPassword"
                          startAdornment={<LockIcon />}
                        />
                        <FormHelperText error></FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-confPassword">
                          Confirme password
                        </InputLabel>
                        <OutlinedInput
                          label="Confirme password"
                          id="outlined-adornment-confPassword"
                          startAdornment={<LockIcon />}
                        />
                        <FormHelperText error></FormHelperText>
                      </FormControl>
                    </Stack>
                  </>
                )}
              </Stack>
            </Card>
            <Button sx={{ float: "right", mt: 2 }} variant="contained">
              Update
            </Button>
          </Grid>
        </Grid>
      </Body>
    </>
  );
}
