import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"

// Define the async thunk for setting the revised layout view data
export const postRevisedGFCView = createAsyncThunk(
    'layout/revised-GFC-view',
    async (data) => {
        return { data, message: "Data successfully set" };
    }
)

// Define the action for resetting the revised view layout
export const resetRevisedViewGFC = createAction('layout/reset-revised-GFC-view')

// Create the slice for handling the state
const revisedGFCViewSlice = createSlice({
    name: 'revised-GFC-view',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(postRevisedGFCView.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(postRevisedGFCView.fulfilled, (state, action) => {
            state.loading = false;
            state.result = action.payload.data;
            state.message = action.payload.message;
        });
        builder.addCase(postRevisedGFCView.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
            state.message = action.error.message;
        });
        builder.addCase(resetRevisedViewGFC, (state) => {
            state.result = [];
            state.loading = false;
            state.error = null;
            state.message = "";
        });
    },
});

// Export the reducer
export const revisedGFCViewReducer = revisedGFCViewSlice.reducer;
