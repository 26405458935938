import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthAxios from '../../config/authAxios'; 

export const addOrganizationApi = createAsyncThunk(
    'rolesAccessManagement/add-role', 
    async (formData, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post('/api/v1/supplier/supplier_company/', formData, {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },           
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

export const editOrganizationApi = createAsyncThunk(
    'rolesAccessManagement/edit-role', 
    async (formData, { rejectWithValue }) => {
        try {
            let id = localStorage.getItem("selectedId")
            const response = await AuthAxios.patch(`/api/v1/supplier/supplier_company/?id=${id}`, formData, {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },           
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

export const deleteOrganizationApi = createAsyncThunk(
    'rolesAccessManagement/delete-role',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.delete(`/api/v1/supplier/supplier_company/?id=${formData?.id}`,{
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },
                    //data:  idToDelete,
                }
                
            )
            return response?.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);


const organizationSlice = createSlice({
    name: 'add-role',
    initialState: { result: [], loading: false, error: null, message: "" },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(addOrganizationApi.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addOrganizationApi.fulfilled, (state, action) => {
            state.loading = false;
            state.roleData = action.payload;
            state.error = null;
        });
        builder.addCase(addOrganizationApi.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

    }
});

export const organizationAddReducer = organizationSlice.reducer;