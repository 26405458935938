import { yupResolver } from "@hookform/resolvers/yup";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import Logo from "../../assets/img/kialogo.png";
import { loginApi, logoutApi } from "../../store/auth/login";
import Style from "../../styles/login.module.scss";
import ForgetPassword from "./ForgetPassword";
import OTPLoginAuth from "./OTPLoginAuth";
import sideCarImg from "../../assets/img/loginBg.png";
import toast from "react-hot-toast";
import { showToast } from "../../toast/toast";
import { loginSchema } from "../validationSchemas";

const schema = yup
  .object({
    email: yup.string().email("Invalid email!").required(),
    password: yup.string().required(),
  })
  .required();

export default function Login() {
  const loginSelector = useSelector((state) => state?.login);
  const { loading } = loginSelector;
  const [errors, setErrors] = useState({});
  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm({ resolver: yupResolver(schema) });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [forgetPassModal, setForgetPassModal] = useState(false);
  const [OTPAuthModel, setOTPAuthModel] = useState(false);

  const [data, setData] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    loginSchema.validate(data, { abortEarly: false })
    .then(() => {
        handleLogin(data);
        setErrors({});
    })
    .catch((validationErrors) => {
        const newErrors = {};
        validationErrors.inner.forEach((error) => {
            newErrors[error.path] = error.message;
        });
        setErrors(newErrors);
    });
  };
  const handleLogin = async (data) => {
    const loginData = await dispatch(loginApi(data));
    if (!loginData?.payload?.error) {
      if(loginData?.payload?.result?.is_admin !== true || loginData?.payload?.result?.is_staff !== true){
        showToast("Not Authorized", 2)
        return;
      }
        setOTPAuthModel(true);
     
    }else{
      showToast(loginData?.payload?.message, 2)
    }
  };

  const handleForgetPassModal = () => {
    if(data?.email==""){
      toast.error("Please provide Email")
      return;
    }

    setForgetPassModal(true);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setData((prev) => {
      return {
        ...prev,
        [e.target.name] : e.target.value}
    })
    updateError(e.target.name)
  }

const updateError = (name)=>{
    setErrors((preValue) => {
        return {
            ...preValue,
            [name] : ""
        }
    })
}


  
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className={Style?.login}>
            <Box sx={{ width: "100%", maxWidth: "480px" }}>
              <form onSubmit={handleSubmit}>
                <Stack spacing={2} justifyContent="center" marginBottom={1}>
                  <Stack alignItems="center">
                    <img src={Logo} alt="logo" width={200} />
                  </Stack>
                  <Stack
                    alignItems="center"
                    sx={{ marginBottom: "20px !important" }}
                  >
                    <Typography variant="p" className="text-center" sx={{maxWidth:"280px"}}>
                      Login to get in the moment updates on the things that interest you.
                    </Typography>
                    {/* <Typography variant="p">
                      things that interest you.
                    </Typography> */}
                  </Stack>
                  <FormControl variant="outlined" fullWidth>
                    <OutlinedInput
                      // {...register("email")}
                      error={errors?.email}
                      value={data?.email}
                      placeholder="Email Address"
                      id="outlined-adornment-email"
                      name="email"
                      onChange={handleChange}
                      startAdornment={<EmailIcon />}
                      inputProps={{ style: { paddingLeft: "8px" } }}
                    />
                    <FormHelperText error>
                      {errors?.email}
                    </FormHelperText>
                  </FormControl>
                  <FormControl variant="outlined" fullWidth>
                    <OutlinedInput
                      // {...register("password")}
                      error={errors?.password}
                      value={data?.password}
                      placeholder="Password"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      onChange={handleChange}
                      id="outlined-adornment-password"
                      startAdornment={<LockIcon />}
                      endAdornment={
                        showPassword ? (
                          <IconButton onClick={() => setShowPassword(false)}>
                            <Visibility />
                          </IconButton>
                        ) : (
                          <IconButton onClick={() => setShowPassword(true)}>
                            <VisibilityOff />
                          </IconButton>
                        )
                      }
                      inputProps={{ style: { paddingLeft: "8px" } }}
                    />
                    <FormHelperText error>
                      {errors?.password}
                    </FormHelperText>
                  </FormControl>
                  <Typography
                    align="right"
                    className={Style?.grayLink}
                  >
                    <span onClick={handleForgetPassModal}>Forget Password?</span>
                  </Typography>
                  <Button
                    disabled={loading}
                    fullWidth
                    variant="contained"
                    type="submit"
                    size="large"
                    sx={{
                      marginBlock: "30px !important",
                      height: { lg: "60px", md: "50px", xs: "40px" },
                      fontSize: { lg: "20px", md: "16px", xs: "14px" },
                    }}
                  >
                    {loading ? <CircularProgress sx={{ color: "#ffffff" }} /> : "Sign In"}{" "}
                  </Button>
                </Stack>
                {/* <Link className="link" to='/become-partner'><Typography align="right">Become a Kia Partner </Typography></Link */}
              </form>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} className={Style.imgSec}>
          <img src={sideCarImg} alt="sideCarImg" />
        </Grid>
      </Grid>

      <ForgetPassword open={forgetPassModal} setOpen={setForgetPassModal} data={data} />
      <OTPLoginAuth open={OTPAuthModel} setOpen={setOTPAuthModel} />
    </>
  );
}
