import { createSlice } from "@reduxjs/toolkit";


const prodcutCRUDSlice = createSlice({
    name: 'prodcut-crud',
    initialState: { dealership: '', product_name: [] },
    reducers: {
        addProduct: (state, action) => {
            state.dealership = action.payload.dealership
            state.product_name = action.payload.product_name
        },
        addNewQytAndPrice: (state, action) => {
            state.product_name[action.payload.idx]['quantity'] = action.payload.quantity
            // const privPrice = Number(state.product_name[action.payload.idx]['price'])
            // const newPrice = Number(action.payload.price)
            // state.product_name[action.payload.idx]['price'] = privPrice + newPrice
        },
        removeNewQytAndPrice: (state, action) => {
            state.product_name[action.payload.idx]['quantity'] = action.payload.quantity
            // const privPrice = Number(state.product_name[action.payload.idx]['price'])
            // const newPrice = Number(action.payload.price)
            // state.product_name[action.payload.idx]['price'] = privPrice - newPrice
        },
        deleteProduct: (state, action) => {
            return state.product_name.splice(action.payload);
        },
    },
})

export const { addProduct, addNewQytAndPrice, removeNewQytAndPrice, deleteProduct } = prodcutCRUDSlice.actions;
export const prodcutCRUDReducer = prodcutCRUDSlice.reducer