import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button, Card, IconButton, Modal, Stack, Typography } from '@mui/material';
import React from 'react';

export default function DeletePlacedProductConf({ open, setOpen, orderData, produtIdx, deleteProd }) {
    const handleClose = () => setOpen(false)

    const handleDeleteProduct = () => {
        orderData.product_name = orderData.product_name.slice(0, deleteProd)
        setOpen(false)
    }

    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <Card className='modal'>
                    <Stack spacing={2}>
                        <Stack alignItems='flex-end'><IconButton onClick={handleClose}><HighlightOffIcon /></IconButton></Stack>
                        <Stack alignItems='center'>
                            <Typography variant='h5' fontWeight={600}>Are you sure you want to delete this item ?</Typography>
                            <Typography>{deleteProd?.name}</Typography>
                        </Stack>
                        <Stack direction='row' spacing={2} justifyContent='center'>
                            <Button variant='outlined' onClick={handleClose}>No</Button>
                            <Button variant='contained' onClick={handleDeleteProduct}>Yes</Button>
                        </Stack>
                    </Stack>
                </Card>
            </Modal>
        </>
    )
}

