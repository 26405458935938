export const handleDownload = (fileName, url) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const fileUrl = `${baseUrl}${url}`;

    fetch(fileUrl)
        .then(response => response.blob())
        .then(blob => {
            const link = document.createElement('a');
            const objectUrl = URL.createObjectURL(blob);
            link.href = objectUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(objectUrl); // Free up memory
        })
        .catch(error => {
            console.error('Error downloading the file:', error);
        });
};
