import { toast } from "react-hot-toast";
import axios from "axios";

const instance = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_BASE_URL,
});


instance.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    return Promise.reject(error);
});


instance.interceptors.response.use(function (response) {
    // toast.success(
    //     response?.data?.message 
    //         ? 
    //         response?.data?.message
    //         : 
    //         response?.data?.status
    //         ?
    //         response?.data?.status
    //         :
    //         response?.data?.results?.message, { position: 'top-right' })
    return response;
}, function (error) {
    // console.log(error?.response?.data?.[0]?.email[0])
    // toast.error(
    //     error?.response?.data?.message
    //     ?
    //     error?.response?.data?.message
    //     :
    //     error?.response?.data?.[0]?.email[0]
    //     , { position: 'top-right' })
    return Promise.reject(error);
});

export default instance