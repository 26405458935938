import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"

export const ordersCountApi = createAsyncThunk(
    'orders/detail',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(`/orders/count`, { params: data })
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const ordersCountSlice = createSlice({
    name: 'detail',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(ordersCountApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(ordersCountApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(ordersCountApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const ordersCountReducer = ordersCountSlice.reducer