import React, { useState } from 'react'

export const ThemeContext = React.createContext();

export default function ThemeContextProvider({ children }) {

    const [drawerShow, setDrawerShow] = useState(true)
    const [darkTheme, setDarkTheme] = useState(false)

    return (
        <ThemeContext.Provider
            value={{
                drawerShow,
                setDrawerShow,
                darkTheme,
                setDarkTheme,
            }}
        >
            {children}
        </ThemeContext.Provider>
    )
}
