// export const convertToFormData=(payload)=>{
//     const form_data = new FormData();
//     let keys=Object.keys(payload);
//     keys.forEach(element => {
//         if(payload[element]!=null&&payload[element]!=undefined)
//         form_data.append(element,payload[element])
//     });
//     return form_data;
// }

export const convertToFormData = (payload) => {
    const form_data = new FormData();
    let keys = Object.keys(payload);
    keys.forEach((element) => {
        if (payload[element] != null && payload[element] !== undefined) {
            if (payload[element] instanceof FileList) {
                for (let i = 0; i < payload[element].length; i++) {
                    form_data.append(element, payload[element][i]);
                }
            } else if(Array.isArray(payload[element])){              
                    form_data.append(element, JSON.stringify(payload[element]));          
            }else {
                form_data.append(element, payload[element]);
            }
        }
    });
    return form_data;
};