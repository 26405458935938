import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  FormControl,
  IconButton,
  Modal,
  OutlinedInput,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ModalCss from "../../styles/modal.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getApplicantsApi } from "../../store/dealorOnBoarding/getApplicant";
import { bulkUploadApi } from "../../store/dealorOnBoarding/bulkUpload";
import { UploadFile } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function BulkUploadOld({ open, setOpen }) {
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.login);
  // const user_type = loginState.result.is_admin ? "SuperAdmin" : "user_type";
  const user_type =  "SuperAdmin";

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validExtensions = [".xlsx", ".xls"];
      const isValidExtension = validExtensions.some((ext) =>
        file.name.toLowerCase().endsWith(ext)
      );
      if (!isValidExtension) {
        alert("Please upload a valid Excel file (.xlsx or .xls)");
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      const uploadeData = dispatch(bulkUploadApi(formData));

      dispatch(getApplicantsApi());
      handleClose();
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className={ModalCss?.customModal}
      >
        <Card className={`modal ${ModalCss?.customModal_md}`}>
          <form>
            <Stack spacing={2} p={3} position={"relative"}>
              <Stack
                alignItems="flex-end"
                position={"absolute"}
                right={5}
                top={5}
              >
                <IconButton onClick={handleClose}>
                  <HighlightOffIcon />
                </IconButton>
              </Stack>
              <Stack alignItems="center">
                <Typography
                  className={ModalCss?.title}
                  variant="h5"
                  fontWeight={600}
                >
                  Bulk Upload
                </Typography>
                <Typography className={ModalCss?.subtitle} fontSize={16}>
                  You can Upload Dealers in Bulk
                </Typography>
              </Stack>

              <Box textAlign={"center"}>
                <Stack width={"90%"} margin={"20px auto 0"} spacing={2}>
                  <FormControl variant="outlined">
                    <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                    >
                      Upload file
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleFileUpload}
                      />
                    </Button>
                    {/* <OutlinedInput
                      
                      type="file"
                      id="outlined-adornment-upload"
                      inputProps={{ style: { paddingLeft: "8px" } }}
                      //startAdornment={<LockIcon />}
                      endAdornment={
                          <IconButton>
                            <UploadFile />
                          </IconButton>
                      }
                    /> */}
                  </FormControl>
                  {/* <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      margin: "30px auto 0 !important",
                      padding: { lg: "16px 10px", xs: "10px" },
                      fontSize: { lg: "18px", xs: "14px" },
                      fontWeight: { lg: "500", xs: "500" },
                      borderRadius: "10px",
                      width: "80%",
                    }}
                  >
                    Reset password
                  </Button> */}
                </Stack>
              </Box>
            </Stack>
          </form>
        </Card>
      </Modal>
    </>
  );
}
