import SpeedIcon from "@mui/icons-material/Speed";
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import PeopleIcon from "@mui/icons-material/People";
import { TextSnippetRounded } from "@mui/icons-material";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ViewComfyOutlinedIcon from '@mui/icons-material/ViewComfyOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';

export const menuItems = [
    {
        title: 'Home',
        icon: <SpeedIcon />,
        key: 'dealerAccordion',
        subItems: [
            { title: 'Dashboard', path: '/' }
        ]
    },
    {
        title: 'Master Data Management',
        icon: <TextSnippetRounded />,
        key: 'masterManagementAccordion',
        subItems: [
            { title: 'Department (KIA)', path: '/master/department' },
            { title: 'Supplier Organization', path: '/master/organization' },
            { title: 'Material Category', path: '/master/material' },
            { title: 'Inventory Management', path: '/master/inventory' }
        ]
    },
    {
        title: 'User Management',
        icon: <ManageAccountsIcon />,
        key: 'userManagementAccordion',
        subItems: [
            { title: 'Dealer Group', path: '/users/dealer' },
            { title: 'Dealer Outlet', path: '/users/dealer-outlet' },
            { title: 'KIA Users', path: '/users/kin' },
            { title: 'Innocean Users', path: '/users/innoceans' },
            { title: 'Supplier Users', path: '/users/suppliers' }
        ]
    },
    {
        title: 'Layout Management',
        icon: <ViewComfyOutlinedIcon />,
        key: 'layoutManagementAccordion',
        subItems: [
            { title: 'Layout Management', path: '/layout' }
        ]
    },
    {
        title: 'Order Management',
        icon: <WidgetsOutlinedIcon />,
        key: 'orderMasteringAccordion',
        subItems: [
            { title: 'View Order', path: '/orders' }
        ]
    },
    {
        title: 'Roles & Permissions',
        icon: <PeopleIcon />,
        key: 'roleManagementAccordion',
        path: '/roles',
    },

    {
        title: 'Profile',
        icon: <AccountCircleIcon />,
        key: 'profileAccordion',
        path: '/user/profile',
    },
    {
        title: 'Logout',
        icon: <LogoutIcon />,
        onclick: 'logout',
    }
   
  ];