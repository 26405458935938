import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"

    export const getRegionForOutletApi = createAsyncThunk(
        'groupDealor/ger-region-for-outlet',
        async (_, { rejectWithValue }) => {
            try {
                const response = await AuthAxios.post(`/api/v1/dealor/filter-data-accordingly`,
                    { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") } }
                )
                return response?.data
            } catch (error) {
                return rejectWithValue(error?.response?.data)
            }
        }
    )
   

const getRegionForOutlet = createSlice({
    name: 'outletRegion',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(getRegionForOutletApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getRegionForOutletApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload
            state.message = action?.payload?.message
        })
        builder.addCase(getRegionForOutletApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })       
    },
})

export const getRegionForOutletReducer = getRegionForOutlet.reducer