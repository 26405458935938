import { yupResolver } from "@hookform/resolvers/yup";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Modal,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { default as React, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { authOtpViaEmailApi } from "../../store/auth/authOtpViaEmail";
import { forgetPasswordViaSMSApi } from "../../store/auth/forgetPasswordViaSMS";
import ResetViaEmailOTP from "./ResetViaEmailOTP";
import ModalCss from "../../styles/modal.module.scss";
const schema = yup
  .object({
    phone_number: yup
      .string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        { message: "Invalid phone number" }
      )
      .required("Phone number is required"),
  })
  .required();

export default function ForgetPasswordSMSConf({ open, setOpen }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const dispatch = useDispatch();

  const [emailVerifyModal, setEmailVerifyModal] = useState(false);

  const handleClose = () => setOpen(false);

  const handleSendVierifyEmail = async (data) => {
    const passData = await dispatch(forgetPasswordViaSMSApi(data));
    if (!passData?.payload?.error) {
      localStorage.setItem(
        "access_token",
        passData?.payload?.result?.access_token
      );
      localStorage.setItem("email", passData?.payload?.result?.email);
      dispatch(authOtpViaEmailApi());
    }
    setEmailVerifyModal(true);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className={ModalCss?.customModal}
      >
        <Card className={`modal ${ModalCss?.customModal_md}`}>
          <form onSubmit={handleSubmit(handleSendVierifyEmail)}>
            <Stack spacing={2} p={3} position={"relative"}>
              <Stack
                alignItems="flex-end"
                position={"absolute"}
                right={5}
                top={5}
              >
                <IconButton onClick={handleClose}>
                  <HighlightOffIcon  />
                </IconButton>
              </Stack>
              <Stack alignItems="center">
                <Typography
                  className={ModalCss?.title}
                  variant="h5"
                  fontWeight={600}
                >
                  Verify Number
                </Typography>
                <Typography className={ModalCss?.subtitle} fontSize={16}>
                  Please enter your Phone Number to verify
                </Typography>
              </Stack>
              <Box
                sx={{ width: "100%", marginBlock: "30px 10px !important" }}
              >
                <Stack
                alignItems="center"
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"center"}
                gap={0}
              >
                <FormControl variant="outlined" sx={{ width: "70%" }}>
                   
                  <OutlinedInput
                    {...register("phone_number")}
                    error={errors?.phone_number?.message}
                    placeholder="Phone Number"
                    id="outlined-adornment-phone_number"
                    startAdornment={<LocalPhoneIcon />}
                    inputProps={{ style: { paddingLeft: "8px" } }}
                    sx={{
                      fontSize: "14px",
                      borderTopRightRadius: "0px !important",
                      borderBottomRightRadius: "0px !important",
                      outline: "none !important",
                    }}
                  />
                  <FormHelperText error>
                    {errors?.phone_number?.message}
                  </FormHelperText>
                </FormControl>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    width: "auto",
                    alignSelf: "start",
                    padding: "12.5px 20px",
                    fontSize: "16px",
                    borderTopLeftRadius: "0px !important",
                    borderBottomLeftRadius: "0px !important",
                    outline: "none",
                  }}
                >
                  Send
                </Button>
              </Stack>
              </Box>
              
            </Stack>
          </form>
        </Card>
      </Modal>

      <ResetViaEmailOTP open={emailVerifyModal} setOpen={setEmailVerifyModal} />
    </>
  );
}
