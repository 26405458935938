import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthAxios from '../../config/authAxios'; 

export const copyEmailTemplate = createAsyncThunk(
    'dealerGroup/add-attachment', 
    async (formData, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post('/api/v1/super_admin/create-copy-email-template/', formData, {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },           
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

const copyEmailTemplateSlice = createSlice({
    name: 'add-dealer-attachement',
    initialState: { result: [], loading: false, error: null, message: "" },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(copyEmailTemplate.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(copyEmailTemplate.fulfilled, (state, action) => {
            state.loading = false;
            state.kinUserData = action.payload;
            state.error = null;
        });
        builder.addCase(copyEmailTemplate.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    }
});

export const copyEmailTemplateReducer = copyEmailTemplateSlice.reducer;