import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button, Card, IconButton, Modal, Stack, Typography } from '@mui/material';
import React from 'react';

export default function OrderPlacedConf({ open, setOpen, orderData }) {
    const handleClose = () => setOpen(false)


    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <Card className='modal'>
                    <Stack spacing={2}>
                        <Stack alignItems='flex-end'><IconButton onClick={handleClose}><HighlightOffIcon /></IconButton></Stack>
                        <Stack alignItems='center'>
                            <Typography variant='h5' fontWeight={600}>Your Order has been Successfully</Typography>
                            <Typography variant='h5' fontWeight={600}>{`Placed for ${orderData?.product_name?.length} items`}</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction='row' sx={{ my: 2 }} justifyContent='center'>
                        <Button variant='contained' onClick={handleClose}>Ok</Button>
                    </Stack>
                </Card>
            </Modal>
        </>
    )
}

