import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthAxios from '../../config/authAxios';

export const addInviteApi = createAsyncThunk(
    'dealerGroup/addInvite',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post('/api/v1/super_admin/invites', formData, {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);


const addInviteSlice = createSlice({
    name: 'addInvite',
    initialState: { result: [], loading: false, error: null, message: "", temp: null },
    reducers: {
        updateInviteApiData(state, action) {
            state.temp = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(addInviteApi.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addInviteApi.fulfilled, (state, action) => {
            state.loading = false;
            state.kinUserData = action.payload;
            state.error = null;
        });
        builder.addCase(addInviteApi.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

    }
});
export const { actions } = addInviteSlice;
const addInviteReducer = addInviteSlice.reducer;
export default addInviteReducer;
