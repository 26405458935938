import React, { useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Body from "../../components/Body";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Style from "../../styles/home.module.scss";
import StyleUser from "../../styles/profile.module.scss";
import FormStyle from "../../styles/field.module.scss";
import DefaultProfileImg from "../../assets/img/defaultProfileImg.png";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  addApplicantApi,
  editApplicantApi,
} from "../../store/dealorOnBoarding/addApplicant";
import { useDispatch, useSelector } from "react-redux";
import { North } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { convertToFormData } from "../ConvertToFormData";
import { getApplicantsApi } from "../../store/dealorOnBoarding/getApplicant";
import { addApplicantSchema } from "../validationSchemas";
import { toggleUserActive } from "../../store/kinUser/getKinUser";

function AddApplicant() {
  const history = useNavigate();
  const loginSelector = useSelector((state) => state?.login);
  //const { loading } = loginSelector;
  const getApplicant = useSelector(
    (state) => state?.getApplicantSliceReducer?.result?.results?.data
  );
  const [applicantData, setApplicantData] = useState(
    getApplicant ? getApplicant : []
  );
  const addApplicantDataRedux = useSelector(
    (state) => state?.addApplicantReducer
  );
  const { loading } = addApplicantDataRedux;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState(null);
  const [addApplicantRequest, setAddApplicantRequest] = useState();

  const handleChange = (event) => {
    const value = event.target.value;
    setAddApplicantRequest((prevState) => ({
      ...prevState,
      [event.target.name]: value,
    }));
    updateError(event.target.name);
  };

  const updateError = (name) => {
    setErrors((preValue) => {
      return {
        ...preValue,
        [name]: "",
      };
    });
  };

  useEffect(() => {
    if (
      localStorage.getItem("selectedId") !== "" &&
      localStorage.getItem("selectedId") !== undefined &&
      getApplicant
    ) {
      const EditData = localStorage.getItem("selectedId")
        ? getApplicant?.filter(
            (row, index) => row.id == localStorage.getItem("selectedId")
          )
        : [];
      setAddApplicantRequest(EditData[0]);
    }
  }, [localStorage.getItem("selectedId"), getApplicant]);

  useEffect(() => {
    if (
      localStorage.getItem("selectedId") !== null &&
      localStorage.getItem("selectedId") !== "" &&
      localStorage.getItem("selectedId") !== undefined
    ) {
      dispatch(getApplicantsApi());
    }
  }, []);

  const handleVerify = () => {
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setAddApplicantRequest((prevState) => ({
      ...prevState,
      image: file,
    }));

    const fileNew = event.target.files[0];

    if (fileNew) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(fileNew);
    }

    //setProfileImage(file)
  };

  const [errors, setErrors] = useState({});

  const addRequestAPI = async () => {
    const data = convertToFormData(addApplicantRequest);
    const addApplicantResponse = await dispatch(addApplicantApi(data));
    dispatch(getApplicantsApi());
  };
  const editRequestAPI = async () => {
    const data = convertToFormData(addApplicantRequest);
    if (profileImage == null) {
      data.delete("image");
    }
    const addApplicantResponse = await dispatch(editApplicantApi(data));
    dispatch(getApplicantsApi());
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    addApplicantSchema
      .validate(addApplicantRequest, { abortEarly: false })
      .then(() => {
        if (
          localStorage?.getItem("selectedId") &&
          localStorage.getItem("selectedId") !== undefined &&
          localStorage.getItem("selectedId") !== ""
        ) {
          editRequestAPI();
        } else {
          addRequestAPI();
        }
        setErrors({});
      })
      .catch((validationErrors) => {
        const newErrors = {};
        validationErrors.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });
        setErrors(newErrors);
      });
  };

  const handleDeactivateAccount = (id, activeState) => {
    const formData = {
        "user_id": id,
        "user_status": activeState
    }
    dispatch(toggleUserActive(formData));
};


  return (
    <>
      <Body>
        <span
          style={{
            cursor: "pointer",
            marginBlock: "15px",
            display: "inline-block",
          }}
          onClick={() => navigate(-1)}
        >
          <svg
            width="7"
            height="12"
            viewBox="0 0 7 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.89888 12L7 10.8485L2.22191 6.0404L6.90169 1.13131L5.76124 0L0 6.08081L5.89888 12Z"
              fill="black"
            />
          </svg>
        </span>
        <form onSubmit={handleSubmit}>
          <Box className={Style?.headerSection}>
            <Typography
              color="#fff"
              fontWeight={500}
              variant="h6"
              marginInlineStart={3}
            >
              Personal Infofmation
            </Typography>
          </Box>
          <Grid container marginBlockStart={2} spacing={2}>
            <Grid
              item
              lg={9}
              md={8}
              sm={12}
              xs={12}
              sx={{ marginBlockEnd: "40px" }}
            >
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="p"
                    gutterBottom
                    className={FormStyle?.fieldsetLabel}
                  >
                    First Name
                  </Typography>
                  {/* <div>
        <label htmlFor="name">Name</label>
        <input
          id="name"
          name="name"
          type="text"
          value={formData.name}
          onChange={handleChange}
        />
        {errors.name && <div>{errors.name}</div>}
      </div> */}

                  <TextField
                    error={Boolean(errors.first_name)}
                    helperText={errors.first_name}
                    value={addApplicantRequest?.first_name}
                    onChange={handleChange}
                    id="input"
                    variant="outlined"
                    fullWidth
                    name="first_name"
                    sx={{ marginTop: "10px" }}
                  />
                  {/* {errors?.first_name && 
                                    <FormHelperText error>
                                        {errors?.first_name}
                                    </FormHelperText>
                                } */}
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="p"
                    gutterBottom
                    className={FormStyle?.fieldsetLabel}
                  >
                    Last Name
                  </Typography>
                  <TextField
                    error={Boolean(errors.last_name)}
                    helperText={errors.last_name}
                    value={addApplicantRequest?.last_name}
                    onChange={handleChange}
                    id="input"
                    variant="outlined"
                    fullWidth
                    name="last_name"
                    sx={{ marginTop: "10px" }}
                  />
                  {/* <FormHelperText error>
                                    {errors?.last_name?.message}
                                </FormHelperText> */}
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="p"
                    gutterBottom
                    className={FormStyle?.fieldsetLabel}
                  >
                    Email
                  </Typography>

                  <TextField
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                    id="input"
                    variant="outlined"
                    fullWidth
                    name="email"
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.email}
                    onChange={handleChange}
                    disabled={
                      localStorage?.getItem("selectedId") &&
                      localStorage.getItem("selectedId") !== ""
                        ? true
                        : false
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            onClick={handleVerify}
                            variant="contained"
                            elevation={0}
                            sx={{
                              lineHeight: "16px",
                              boxShadow: "none !important",
                              bgcolor: "#D9EAF6",
                              color: "#05141F",
                              "&:hover": { bgcolor: "#000", color: "#fff" },
                            }}
                          >
                            Verify
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />

                  {/* <TextField
                                    {...register("email")}
                                    error={Boolean(errors.email)}
                                    helperText={errors.email?.message || ""}
                                    value={addApplicantRequest?.email}
                                    defaultValue={initialValues?.email}
                                    onChange={handleChange}
                                    type="email"
                                    variant="outlined"
                                    //disabled={localStorage.getItem("selectedId")!=="" ? true : false}
                                    
                                    name="email"
                                    fullWidth
                                    //onChange={handleChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Button
                                                    onClick={handleVerify}
                                                    variant="contained"
                                                    sx={{
                                                        bgcolor: "#D9EAF6",
                                                        color: "#05141F",
                                                        "&:hover": { bgcolor: "#000", color: "#fff" },
                                                    }}
                                                >
                                                    Verify
                                                </Button>
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{ marginTop: "10px" }}
                                // Add any other TextField props as needed
                                /> */}
                  {/* <FormHelperText error>
                                    {errors?.email?.message}
                                </FormHelperText> */}
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="p"
                    gutterBottom
                    className={FormStyle?.fieldsetLabel}
                  >
                    Number
                  </Typography>
                  <TextField
                    error={Boolean(errors.phone)}
                    helperText={errors.phone}
                    type="text"
                    variant="outlined"
                    value={addApplicantRequest?.phone}
                    name="phone"
                    fullWidth
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography variant="p">+91 | </Typography>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            onClick={handleVerify}
                            variant="contained"
                            sx={{
                              lineHeight: "16px",
                              boxShadow: "none !important",
                              bgcolor: "#D9EAF6",
                              color: "#05141F",
                              "&:hover": { bgcolor: "#000", color: "#fff" },
                            }}
                          >
                            Verify
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                    sx={{ marginTop: "10px" }}
                  />
                  {/* <FormHelperText error>
                                    {errors?.phone?.message}
                                </FormHelperText> */}
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="p"
                    gutterBottom
                    className={FormStyle?.fieldsetLabel}
                  >
                    Address
                  </Typography>
                  <TextField
                    error={Boolean(errors.address)}
                    helperText={errors.address}
                    id="input"
                    variant="outlined"
                    fullWidth
                    name="address"
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.address}
                    onChange={handleChange}
                  />
                  {/* <FormHelperText error>
                                    {errors?.address?.message}
                                </FormHelperText> */}
                </Grid>
                <Grid item md={6} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <Typography
                        variant="p"
                        gutterBottom
                        className={FormStyle?.fieldsetLabel}
                      >
                        City
                      </Typography>
                      <FormControl fullWidth>
                        <Select
                          // error={Boolean(errors.city)}
                          // helperText={errors.city}
                          id="demo-simple-select"
                          value={
                            addApplicantRequest?.city
                              ? addApplicantRequest?.city
                              : ""
                          }
                          name="city"
                          onChange={handleChange}
                          sx={{ marginTop: "10px" }}
                        >
                          <MenuItem value="">Select City</MenuItem>
                          <MenuItem value="Chandigarh">Chandigarh</MenuItem>
                          <MenuItem value="Jharkhand">Jharkhand</MenuItem>
                          <MenuItem value="Gujarat">Gujarat</MenuItem>
                        </Select>
                        <FormHelperText error>{errors?.city}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Typography
                        variant="p"
                        gutterBottom
                        className={FormStyle?.fieldsetLabel}
                      >
                        Pincode
                      </Typography>
                      <TextField
                        error={Boolean(errors.pincode)}
                        helperText={errors.pincode}
                        type="text"
                        variant="outlined"
                        value={addApplicantRequest?.pincode}
                        name="pincode"
                        onChange={handleChange}
                        fullWidth
                        sx={{ marginTop: "10px" }}
                        // Add any other TextField props as needed
                      />
                      {/* <FormHelperText error>
                                            {errors?.pincode?.message}
                                        </FormHelperText> */}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="p"
                    gutterBottom
                    className={FormStyle?.fieldsetLabel}
                  >
                    Zone
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      // error={Boolean(errors.zone)}
                      // helperText={errors.zone}
                      id="demo-simple-select"
                      value={
                        addApplicantRequest?.zone
                          ? addApplicantRequest?.zone
                          : ""
                      }
                      onChange={handleChange}
                      name="zone"
                      sx={{ marginTop: "10px" }}
                    >
                      <MenuItem value="">Select Zone</MenuItem>
                      <MenuItem value="East">East</MenuItem>
                      <MenuItem value="West">West</MenuItem>
                      <MenuItem value="North">North </MenuItem>
                      <MenuItem value="South">South </MenuItem>
                    </Select>
                    <FormHelperText error>{errors?.zone}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="p"
                    gutterBottom
                    className={FormStyle?.fieldsetLabel}
                  >
                    State
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      // error={Boolean(errors.state)}
                      // helperText={errors.state}
                      id="demo-simple-select"
                      value={
                        addApplicantRequest?.state
                          ? addApplicantRequest?.state
                          : ""
                      }
                      onChange={handleChange}
                      name="state"
                      sx={{ marginTop: "10px" }}
                    >
                      <MenuItem value="">Select State</MenuItem>
                      <MenuItem value="Uttarakhand">Uttarakhand</MenuItem>
                      <MenuItem value="Goa">Goa</MenuItem>
                      <MenuItem value="Manipur">Manipur</MenuItem>
                      <MenuItem value="Assam">Assam</MenuItem>
                      <MenuItem value="Chandigarh">Chandigarh</MenuItem>
                      <MenuItem value="Andhra Pradesh">Andra Pradesh</MenuItem>
                    </Select>
                    <FormHelperText error>{errors?.state}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid
                        item
                        lg={3}
                        md={4}
                        sm={12}
                        xs={12}
                        sx={{ marginBlockEnd: "40px" }}
                    >
                        <Card className={StyleUser?.card}>
                            <Box className={StyleUser.profileSection}>

                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                    badgeContent={
                                        <IconButton className={StyleUser.uploadicon}>
                                            <input
                                                accept="image/*"
                                                style={{ display: "none", margin: "0" }}
                                                id="raised-button-file"
                                                multiple
                                                type="file"
                                            />
                                            <label htmlFor="raised-button-file">
                                                <CameraAltIcon color="dark" />
                                            </label>
                                        </IconButton>
                                    }
                                >
                                    <Avatar
                                        alt="Travis Howard"
                                        src={DefaultProfileImg}
                                        sx={{
                                            width: "150px",
                                            height: "150px",
                                            border: "3px solid #fff",
                                        }}
                                    />
                                </Badge>
                            </Box>
                        </Card>
                    </Grid> */}
            <Grid
              item
              lg={3}
              md={4}
              sm={12}
              xs={12}
              sx={{ marginBlockEnd: "40px" }}
            >
              <Card className={StyleUser?.card}>
                <Box className={StyleUser.profileSection}>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent={
                      <IconButton className={StyleUser.uploadicon}>
                        <input
                          accept="image/*"
                          style={{ display: "none", margin: "0" }}
                          id="raised-button-file"
                          type="file"
                          onChange={handleFileChange}
                        />
                        <label htmlFor="raised-button-file">
                          <CameraAltIcon color="dark" />
                        </label>
                      </IconButton>
                    }
                  >
                    {/* {profileImage && (
                    <img
                    src={profileImage}
                    alt="Uploaded"
                    style={{ maxWidth: "100%", maxHeight: "300px", marginTop: "10px" }}
                    />
                )} */}
                    <Avatar
                      alt="Profile Picture"
                      src={
                        profileImage
                          ? profileImage
                          : `${process.env.REACT_APP_BASE_URL}${addApplicantRequest?.image}`
                      }
                      sx={{
                        width: "150px",
                        height: "150px",
                        border: "3px solid #fff",
                      }}
                      className={StyleUser?.profileImg}
                    />
                  </Badge>
                </Box>
                {localStorage.getItem("selectedId") && (<>
                  <Box component={"div"} className={StyleUser?.profileInfo}>
                  <Typography className={StyleUser?.profileName}>
                    {addApplicantRequest?.first_name}  {addApplicantRequest?.last_name}
                  </Typography>
                  <Typography className={StyleUser?.profilePosition}>
                    Designation
                  </Typography>
                </Box>

                </>)}
                
              </Card>

              <Button
                variant={addApplicantRequest?.is_active ? "outlined" : "success"}
                // variant="success"
                size="large"
                sx={{
                  padding: "15px 70px",
                  fontSize: "17px",
                  borderRadius: "8px",
                  backgroundColor: "white",
                    color: addApplicantRequest?.is_active ? "red" : "green",
                  // color: "green",
                  border: "1px solid black",
                  fontWeight: "bold",
                  marginBlock: "20px",
                }}
                disabled
                // onClick={handleDeactivateAccount}
                onClick={() =>
                  handleDeactivateAccount(
                    addApplicantRequest?.id,
                    !addApplicantRequest?.is_active
                  )
                }
              >
                {addApplicantRequest?.is_active
                  ? "Deactivate Account"
                  : "Activate Account"}
              </Button>
            </Grid>
          </Grid>
          <Box className={Style?.headerSection}>
            <Typography
              color="#fff"
              fontWeight={500}
              variant="h6"
              marginInlineStart={3}
            >
              Additional Infofmation
            </Typography>
          </Box>
          <Grid container marginBlockStart={2} spacing={2}>
            <Grid item xs={12} sx={{ marginBlockEnd: "30px" }}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Typography
                    variant="p"
                    gutterBottom
                    className={FormStyle?.fieldsetLabel}
                  >
                    Outlet Code
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    value={addApplicantRequest?.outlet_code}
                    name="outlet_code"
                    onChange={handleChange}
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    // Add any other TextField props as needed
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Count
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    value={addApplicantRequest?.count}
                    name="count"
                    onChange={handleChange}
                    sx={{ marginTop: "10px" }}
                    // Add any other TextField props as needed
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Status
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      id="demo-simple-select"
                      value={
                        addApplicantRequest?.status
                          ? addApplicantRequest?.status
                          : ""
                      }
                      onChange={handleChange}
                      name="status"
                      sx={{ marginTop: "10px" }}
                    >
                      <MenuItem value="">Select Status</MenuItem>
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="Twenty">Twenty</MenuItem>
                      <MenuItem value="Thirty">Thirty</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Zone
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      id="demo-simple-select"
                      value={
                        addApplicantRequest?.zone
                          ? addApplicantRequest?.zone
                          : ""
                      }
                      onChange={handleChange}
                      sx={{ marginTop: "10px" }}
                      name="zone"
                    >
                      <MenuItem value="">Select Zone</MenuItem>
                      <MenuItem value="Ten">Ten</MenuItem>
                      <MenuItem value="Twenty">Twenty</MenuItem>
                      <MenuItem value="Thirty">Thirty</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    State
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      id="demo-simple-select"
                      value={
                        addApplicantRequest?.city
                          ? addApplicantRequest?.city
                          : ""
                      }
                      name="city"
                      onChange={handleChange}
                      sx={{ marginTop: "10px" }}
                    >
                      <MenuItem value="">Select City</MenuItem>
                      <MenuItem value="Ten">Ten</MenuItem>
                      <MenuItem value="Twenty">Twenty</MenuItem>
                      <MenuItem value="Thirty">Thirty</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Tier
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.tier}
                    name="tier"
                    onChange={handleChange}
                    // Add any other TextField props as needed
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Cluster
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.cluster}
                    onChange={handleChange}
                    name="cluster"
                    // Add any other TextField props as needed
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    LOA/Ref. No.
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.loa_ref_no}
                    onChange={handleChange}
                    name="loa_ref_no"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    LOA/Ref Date
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker", "DatePicker"]}>
                      <DatePicker defaultValue={dayjs("2022-04-17")} />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Partner Count
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.partner_count}
                    name="partner_count"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Main Dealer Code
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.main_dealer_code}
                    name="main_dealer_code"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Group Count
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.group_count}
                    name="group_count"
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Group Code
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.group_code}
                    name="group_code"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Cluster Group
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.cluster_group}
                    name="cluster_group"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Trade Name
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.trade_name}
                    name="trade_name"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    LOI Status
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.loi_status}
                    name="loi_status"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    LOI No.
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.loi_no}
                    name="loi_no"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    LOI Date
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.loi_date}
                    name="loi_date"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Remarks
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.remarks}
                    name="remarks"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Facility Type(As Per LOA)
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.facility_type}
                    name="facility_type"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Correspondence Address
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.correspondence_address}
                    name="correspondence_address"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Pin Code1S Address
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.pincode_1S_address}
                    name="pincode_1S_address"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Pincode (2s)
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.pincode_2s}
                    name="pincode_2s"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Showroom Ownership
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.showroom_ownership}
                    name="showroom_ownership"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Workshop Ownership
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.workshop_ownership}
                    name="workshop_ownership"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Frontage in Ft
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.frontage_in_ft}
                    name="frontage_in_ft"
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box className={Style?.headerSection}>
            <Typography
              color="#fff"
              fontWeight={500}
              variant="h6"
              marginInlineStart={3}
            >
              Showroom Information
            </Typography>
          </Box>
          <Grid container marginBlockStart={2} spacing={2}>
            <Grid item xs={12} sx={{ marginBlockEnd: "30px" }}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Covered Area Showroom(in sq.ft.)
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.covered_area_showroom}
                    name="covered_area_showroom"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Covered Area Workshop(in sq.ft.)
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.covered_area_workshop}
                    name="covered_area_workshop"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Facility Type
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.facility_type}
                    name="facility_type"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Showroom Type
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.showroom_type}
                    name="showroom_type"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Workshop Type
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.workshop_type}
                    name="workshop_type"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    3S Address
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.three_s_address}
                    name="three_s_address"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    3S Pin Code
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.three_s_pincode}
                    name="three_s_pincode"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    1S Address
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.one_s_address}
                    name="one_s_address"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    1S Pin Code
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.one_s_pincode}
                    name="one_s_pincode"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Workshop Longitude
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.workshop_longitude}
                    name="workshop_longitude"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Workshop Latitude
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.workshop_latitude}
                    name="workshop_latitude"
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box className={Style?.headerSection}>
            <Typography
              color="#fff"
              fontWeight={500}
              variant="h6"
              marginInlineStart={3}
            >
              Company Information
            </Typography>
          </Box>
          <Grid container marginBlockStart={2} spacing={2}>
            <Grid item xs={12} sx={{ marginBlockEnd: "30px" }}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Company Name
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.company_name}
                    name="company_name"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Previous Name (if any)
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.previous_name}
                    name="previous_name"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    CIN
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.CIN}
                    name="CIN"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Registered Address
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.registered_address}
                    name="registered_address"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Incorporation Date
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.incorporation_date}
                    name="incorporation_date"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Date of Name change
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.date_of_name_change}
                    name="date_of_name_change"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    DPShare
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.dpshare}
                    name="dpshare"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Deviation Approved
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.deviation_approved}
                    name="deviation_approved"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Deviation Case
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.devation_case}
                    name="devation_case"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Company Reason
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.company_reason}
                    name="company_reason"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Shareholding Reason
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.shareholding_reason}
                    name="shareholding_reason"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    PAN
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.PAN}
                    name="PAN"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    GST
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.GST}
                    name="GST"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    JDP/Non JDP
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.jdp}
                    name="jdp"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Elevation
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.elevation}
                    name="elevation"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Showroom Frontage
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.showroom_frontage}
                    name="showroom_frontage"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Showroom Display Area
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.showroom_display_area}
                    name="showroom_display_area"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Workshop Covered Area
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.workshop_covered_area}
                    name="workshop_covered_area"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Plot Area(3S / 2S)
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.plot_area}
                    name="plot_area"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Display Cars
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.display_cars}
                    name="display_cars"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Type of Dealership (Main / Outlet)
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.type_of_dealership}
                    name="type_of_dealership"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Workshop Type (Mech / Mech + B&p / B&p)
                  </Typography>
                  <TextField
                    id="input"
                    variant="outlined"
                    fullWidth
                    sx={{ marginTop: "10px" }}
                    value={addApplicantRequest?.workshop_type}
                    name="workshop_type"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="p" gutterBottom>
                    Experience
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      id="demo-simple-select"
                      value={
                        addApplicantRequest?.experience
                          ? addApplicantRequest?.experience
                          : ""
                      }
                      name="experience"
                      onChange={handleChange}
                      sx={{ marginTop: "10px" }}
                    >
                      <MenuItem value="">Select City</MenuItem>
                      <MenuItem value="Gujarat">Gujarat</MenuItem>
                      <MenuItem value="Goa">Goa</MenuItem>
                      <MenuItem value="Jharkhand">Jharkhand</MenuItem>
                      <MenuItem value="Delhi">Delhi</MenuItem>
                      <MenuItem value="Haryana">Haryana</MenuItem>
                      <MenuItem value="Andra Pradesh">Andra Pradesh</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={12} xs={12}>
                  {/* <Button 
                            type="submit" 
                            variant="contained"
                            sx={{
                                marginBlock: "30px !important",
                                height: { lg: "60px", md: "50px", xs: "40px" },
                                fontSize: { lg: "20px", md: "16px", xs: "14px" },
                                }}
                            
                            >Submit</Button> */}
                  <Button
                    disabled={loading}
                    // fullWidth
                    variant="contained"
                    type="submit"
                    size="large"
                    sx={{
                      marginBlock: "30px !important",
                      height: { lg: "60px", md: "50px", xs: "40px" },
                      fontSize: { lg: "20px", md: "16px", xs: "14px" },
                    }}
                  >
                    {loading ? <CircularProgress sx={{ color: "#ffffff" }} /> : "Submit"}{" "}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Body>
    </>
  );
}

export default AddApplicant;
