import React, { useEffect, useState } from "react";
import { convertToFormData } from "../ConvertToFormData";
import { useDispatch, useSelector } from "react-redux";
import { schematicLayout } from "../../store/home/schematicLayout";
import { schematicAdminToDealer } from "../../store/home/schematicAdminToDealer";
import Feedback from "../reusable/Feedback";
import { Box, Button, Grid, TextareaAutosize, Tooltip, Typography } from "@mui/material";
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import { handleDownload } from "../reusable/downloadFile";
import pdf from '../../assets/img/pdf.png'
import cad from '../../assets/img/cad.png'
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { showToast } from "../../toast/toast";
import DoneIcon from '@mui/icons-material/Done';
export default function SchematicLayout() {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const formId = useSelector((state) => state?.inputDrawingByClusterReducer?.result);
    const {result : revisedSchematicLayoutData, loading : revisedShematicLayoutLoading} = useSelector((state) => state?.revisedLayoutViewReducer);
    const {result : schematicLayoutData, loading : schematicLayoutLoading} = useSelector((state) => state?.schematicLayoutReducer);
    const handleClose = () => setOpen(false);
    const getInputDrawing = async () => {
        const formD = {
            'form_id': formId?.data?.id
        }
        const data = convertToFormData(formD);
        try {
            const response = await dispatch(schematicLayout(data));
            setData(response?.payload?.data);
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getInputDrawing();
    }, []);

    const postToDealer = async () => {

        const formData = convertToFormData({ 'schematic_id': data?.id });

        try {
            const response = await dispatch(schematicAdminToDealer(formData));
            showToast(response?.payload?.message, 1);
        } catch (error) {
            console.error("Error in sending data to Kin:", error);
        }
    };
    return (
        <>
            <Box className="h-100 d-flex flex-column justify-content-between">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                        <Typography variant="h6" className="fs-17  fw-400">Schematic Layout</Typography>
                    </Grid>
                    {data?.schematic_cad && data?.schematic_pdf ? (
    <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
            <Box className="d-flex flex-wrap w-100" sx={{ gap: "24px" }}>
                <Box sx={{ width: "213px" }}>
                    {data?.schematic_pdf?.map((schematicLayoutData) => (
                        <Box key={schematicLayoutData.files} className="upload-box bg-white text-center">
                            <Box className="m-auto p-relative">
                                <Tooltip title={schematicLayoutData.files} placement="top" className={` uploadedfilename`}>
                                    <img width={40} height={40} src={pdf} className="my-auto" />
                                    <Typography>{'PDF File'}</Typography>
                                    <DownloadForOfflineRoundedIcon className="my-auto" onClick={() => handleDownload(new Date() + 'schematic_cad', schematicLayoutData.files)} />
                                    <FullscreenIcon className="my-auto" onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${schematicLayoutData.files}`)} />
                                </Tooltip>
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Box sx={{ width: "213px" }}>
                    {data?.schematic_cad?.map((schematicLayoutData) => (
                        <Box key={schematicLayoutData.files} className="upload-box bg-white text-center">
                            <Box className="m-auto p-relative">
                                <Tooltip title={schematicLayoutData.files} placement="top" className={` uploadedfilename`}>
                                    <img width={40} height={40} src={cad} className="my-auto" />
                                    <Typography>{'CAD File'}</Typography>
                                    <DownloadForOfflineRoundedIcon className="my-auto" onClick={() => handleDownload(new Date() + 'schematic_cad', schematicLayoutData.files)} />
                                    <FullscreenIcon className="my-auto" onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${schematicLayoutData.files}`)} />
                                </Tooltip>
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Box className="w-100" sx={{ marginTop: '40px' }}>
                    <Box className="d-flex" sx={{ gap: '18px' }}>
                        <Typography variant="h6" className="fs-17 my-auto">Attached Design Estimates</Typography>
                    </Box>
                    <Box sx={{ height: "fit-content" }}>
                        <Tooltip title={data?.design_estimate?.estimate} placement="top" className={` uploadedfilename`}>
                            <Typography>{'Design Estimate'}</Typography>
                            <DownloadForOfflineRoundedIcon onClick={() => handleDownload(new Date() + 'schematic_designEstimate', data?.design_estimate?.estimate)} />
                        </Tooltip>
                    </Box>
                </Box>
                <Box className="w-100" sx={{ marginTop: '5px' }}>
                    <Box className="d-flex" sx={{ gap: '18px' }}>
                        <Typography variant="h6" className="fs-17 " sx={{ marginTop: "50px" }}>Comment</Typography>
                    </Box>
                    <Box sx={{ height: "fit-content" }}>
                        <TextareaAutosize
                            disabled
                            value={data?.comment}
                            id="input"
                            fullWidth
                            name="comment"
                            style={{ maxWidth: "500px", width: "100%", border: "1px solid #C4D3DF", borderRadius: "5px", padding: "10px" }}
                            className="bg-white"
                            minRows={6} />
                    </Box>
                </Box>
            </Box>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex" sx={{ gap: "23px" }}>
                {data?.approve_by_admin ?
                    <Button className="black-btn" style={{ color: "green" }}><DoneIcon style={{ marginRight: "2px", marginTop: "-5px" }} /> Approved</Button>
                    :
                    <Button className="black-btn" onClick={() => postToDealer()}>Approve</Button>
                }
                <Button className="white-btn" onClick={() => setOpen(true)}>Request for revision</Button>
            </Grid>
        </Grid>
    </Grid>
) : (
    <Typography>No data available</Typography>
)}


                    </Grid>
            </Box>

            <Feedback open={open} handleClose={handleClose} />
        </>
    );
}