import React, { useState, useRef, useEffect } from "react";
import Body from "../../../components/Body";
import {
    FormControl,
    Grid,
    Typography,
    TextField,
    Button,
    IconButton,
    Box,
    CircularProgress
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FormStyle from "../../../styles/field.module.scss";
import Style from "../../../styles/home.module.scss";
import { categorySchema } from "../../validationSchemas";
import { addCategoryApi, editCategory } from "../../../store/category";
import { showToast } from "../../../toast/toast";
import { useDispatch, useSelector } from "react-redux";
import AddInventoryModal from '../../reusable/AddInventoryModal';
import { Navigate, redirect, useNavigate } from "react-router-dom";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
function AddMaterial() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {result:getAllCategory} = useSelector((state) => state?.categoryReducer)
    const [image, setImage] = useState(null);
    const [categoryData, setCategoryData] = useState({});
    const [errors, setErrors] = useState({});
    const [openInventoryModal, setOpenInventoryModal] = useState(false);
    const fileInputRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const handleInventoryOpen = () => setOpenInventoryModal(true);
    const handleInventoryClose = () => setOpenInventoryModal(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setCategoryData(prevState => ({
            ...prevState,
            [name]: value
        }));
        updateError(name);
    };

    const updateError = (name) => {
        setErrors((prevState) => ({
            ...prevState,
            [name]: "",
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        categorySchema.validate(categoryData, { abortEarly: false })
            .then(() => {
                if (localStorage?.getItem("selectedId")) {
                    editRequestAPI();
                } else {
                    addRequestAPI();
                }
                setErrors({});
            })
            .catch((validationErrors) => {
                const newErrors = {};
                validationErrors.inner.forEach((error) => {
                    newErrors[error.path] = error.message;
                });
                setErrors(newErrors);
            });
    };

    const addRequestAPI = async () => {
        setLoading(true)
        var data = new FormData();
        data.append("category", categoryData?.category);
        data.append("snap", categoryData?.snap);
        const addCategoryResponse = await dispatch(addCategoryApi(data));
        if (addCategoryResponse?.payload?.status === "error") {
            if (addCategoryResponse?.payload?.message) {
                const messages = addCategoryResponse.payload.message;
              
                if (Array.isArray(messages)) {
                  // Handle case where `message` is an array
                  messages.forEach((element) => {
                    Object.keys(element).forEach((key) => {
                      element[key].forEach((msg) => {
                        showToast(`${key}: ${msg}`, 2); // Show key (e.g., category) with the error message
                      });
                    });
                  });
                } else if (typeof messages === 'object') {
                  // Handle case where `message` is an object
                  Object.keys(messages).forEach((key) => {
                    messages[key].forEach((msg) => {
                      showToast(`${key}: ${msg}`, 2); // Show key (e.g., category) with the error message
                    });
                  });
                } else {
                  // If it's neither an array nor an object
                  showToast('Unexpected message format', 2);
                }
              
                setLoading(false);
              }
            
            //showToast(addCategoryResponse?.payload?.message?.category, 2);
            
            return;
        }
        showToast('Category added successfully', 1);
        setLoading(false)
        navigate('/master/material')
        //navigate(-1)
    };

    const editRequestAPI = async () => {
        var data = new FormData();
        data.append("id", localStorage?.getItem("selectedId"));
        data.append("category", categoryData?.category);
        if(image){
            data.append("snap", categoryData?.snap);
        }
        
        // const data= {
        //     id: Number(localStorage?.getItem("selectedId")),
        //     category: categoryData?.category
        // }
        try {
           const response =  await dispatch(editCategory(data));
           if (response?.payload?.status === "error") {
            showToast(response?.payload?.message, 2);
            return;
        }
            showToast("Category edited successfully", 1);
            navigate('/master/material')
        } catch (err) {
            showToast("Something went wrong", 2);
        }
    };

    const handleImageChange = (event) => {
        const file = event.currentTarget.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
            setCategoryData(prevState => ({
                ...prevState,
                snap: file
            }));
            updateError("snap");
        }
    };

    const handleImageDelete = () => {
        setImage(null);
        setCategoryData(prevState => ({
            ...prevState,
            snap: null
        }));
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    useEffect(() => {
        if (localStorage.getItem("selectedId") && getAllCategory) {
            const EditData = localStorage.getItem("selectedId") ? getAllCategory?.filter((row, index) => row.id == localStorage.getItem('selectedId')) : []
            setCategoryData({
                category: EditData?.[0]?.category,
                snap: EditData?.[0]?.snap
            })
        }
    }, [localStorage.getItem("selectedId"), getAllCategory]);
    
    return (
        <>
        <Body>
      
            <KeyboardArrowLeftIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
       
            <form onSubmit={handleSubmit}>
                <Box className={Style?.headerSection}>
                    <Typography
                        color="#fff"
                        fontWeight={500}
                        variant="h6"
                        marginInlineStart={3}
                    >
                        Material
                    </Typography>
                </Box>
                <Grid container marginBlockStart={2} spacing={2}>
                    <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{ marginBlockEnd: "40px" }}
                    >
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <Grid container spacing={2}>

                                    <Grid item md={12} xs={12}>
                                        <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                            Category Name
                                        </Typography>
                                        <TextField
                                            error={Boolean(errors.category)}
                                            helperText={errors.category}
                                            value={categoryData?.category || ""}
                                            onChange={handleChange}
                                            id="input"
                                            variant="outlined"
                                            fullWidth
                                            name="category"
                                            sx={{ marginTop: "10px" }}
                                        />
                                    </Grid>

                                    <Grid item md={12} xs={12}>
                                        <Typography variant="p" gutterBottom className={FormStyle?.fieldsetLabel}>
                                            Choose a picture
                                        </Typography>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ accept: "image/png, image/jpeg" }}
                                            inputRef={fileInputRef}
                                            InputProps={{
                                                inputComponent: "input",
                                                type: "file",
                                                id: "snap",
                                                name: "snap",
                                            }}
                                            onChange={handleImageChange}
                                            error={Boolean(errors.snap)}
                                            helperText={errors.snap}
                                        />
                                 
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={6} xs={12}>
                            {!image && localStorage.getItem("selectedId") && (
                                            <Grid item xs={12} sx={{ marginTop: "10px" }}>
                                                <img src={`${process.env.REACT_APP_BASE_URL}${categoryData?.snap}`} alt="Profile" style={{height: "200px", width: "auto"}} />
                                                
                                                
                                            </Grid>
                                        )}
                                        {image  && (
                                            <Grid item xs={12} sx={{ marginTop: "10px" }}>
                                                <img src={image} alt="Profile" style={{height: "200px", width: "auto"}}/>
                                                {!localStorage.getItem("selectedId") && <IconButton onClick={handleImageDelete}>
                                                    <DeleteIcon />
                                                </IconButton>}
                                                
                                            </Grid>
                                        )}
                            </Grid>

                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Button disabled={loading} className="black-btn" variant="contained" type="submit">

                                {loading ? <CircularProgress sx={{ color: "#ffffff" }} /> : localStorage.getItem("selectedId") ? "Update category" : "Create category"}

                            </Button>
                    </Grid>
                    </Grid>
                </Grid>
            </form>
            <AddInventoryModal open={openInventoryModal} handleClose={handleInventoryClose} />
        </Body>
        </>
    );
}

export default AddMaterial;
