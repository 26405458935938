import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const DeleteConfirmationModal = ({ open, handleClose, handleConfirm ,deleteType}) => {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this {deleteType}? This action cannot be undone.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" variant='contained'>Cancel</Button>
                <Button onClick={handleConfirm} color="error" variant='contained'>Delete</Button>
            </DialogActions>
        </Dialog>
    );
};
export default DeleteConfirmationModal;