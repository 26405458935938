import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthAxios from '../../config/authAxios'; 

export const addCategoryApi = createAsyncThunk(
    'category/add-category', 
    async (formData, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post('/api/v1/prime_vendor/innmaterial_category/', formData, {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },           
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

export const editCategory = createAsyncThunk(
    'category/editCategory',
    async (formData, { rejectWithValue }) => {
        try {
            let id = localStorage.getItem("selectedId");
            const response = await AuthAxios.patch(`/api/v1/prime_vendor/innmaterial_category/`, formData, {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },
            });
            
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

export const deleteCategory  = createAsyncThunk(
    'Category/deleteCategory',
    async (idToDelete, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.delete(`/api/v1/prime_vendor/innmaterial_category/`,{
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },
                    data:  idToDelete,
                }
                
            )
            return response?.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);


const categorySlice = createSlice({
    name: 'add-category',
    initialState: { result: [], loading: false, error: null, message: "" },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(addCategoryApi.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addCategoryApi.fulfilled, (state, action) => {
            state.loading = false;
            state.roleData = action.payload;
            state.error = null;
        });
        builder.addCase(addCategoryApi.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

    }
});

export const categoryReducer = categorySlice.reducer;