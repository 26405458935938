import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthAxios from '../../config/authAxios'; 

export const editUserDetailApi = createAsyncThunk(
    'userProfile/editUserDetail',
        async (formData, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post('/api/v1/login/edit_user_detail/', formData, {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },           
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

const editUserDetailSlice = createSlice({
    name: 'editUserDetail',
    initialState: { result: [], loading: false, error: null, message: "" },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(editUserDetailApi.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editUserDetailApi.fulfilled, (state, action) => {
            state.loading = false;
            state.userDetailData = action.payload;
            state.error = null;
        });
        builder.addCase(editUserDetailApi.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    }
});

export const editUserDetailReducer = editUserDetailSlice.reducer;        