import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"

export const authOtpViaEmailApi = createAsyncThunk(
    'auth/auth-otp-via-email',
    async ({ type, user_type, email, forget_password }, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post(`/api/v1/login/send_otp/`, { type, user_type, email, forget_password },    
                // {
                //     headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },           
                // }
            )   
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const authOtpViaEmailSlice = createSlice({
    name: 'auth-otp-via-email',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(authOtpViaEmailApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(authOtpViaEmailApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(authOtpViaEmailApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const authOtpViaEmailReducer = authOtpViaEmailSlice.reducer


