import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Style from "../../styles/home.module.scss";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useNavigate } from "react-router-dom";
import layoutIconSvg from "../../assets/img/layout-svg.png";
import { ordersApi } from "../../store/orders/orders";
import { useDispatch, useSelector } from "react-redux";
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';

export default function OrdersDashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {loading:orderLoading, error:orderError, result:orderList}  = useSelector(state => state?.orders);

  const handleNavigate = (url) => {
    navigate(`/${url}`);
  }
  useEffect(() => {
    dispatch(ordersApi())
},[dispatch])
  return (
    <Box>
      <Box className={Style?.headerSection}>
        <Typography className={Style?.cardHeading} variant="h6">
          Orders
        </Typography>
      </Box>

      <Stack sx={{ mt: 2 }}>
        <Grid container spacing={1}>
          
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Card className="card2" style={{cursor:'pointer'}} onClick={()=>handleNavigate('orders')}>
              <Stack direction="row" spacing={1} className="card2Inner">
                <WidgetsOutlinedIcon sx={{ mr: 1.5 }}/>
                <Box sx={{display:"block"}}>
                  <Typography className="card2Content" >Total Orders</Typography>
                  <Typography className={Style.cardValue} variant="h5">
                      {orderList?.data?.length}
                    </Typography>
                </Box>
              </Stack>
            </Card>
          </Grid>
          
        </Grid>
      </Stack>
    </Box>
  );
}
