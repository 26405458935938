import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"

export const bulkUploadApi = createAsyncThunk(
    'onBoarding/bulk-upload',
    async ( formData, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post(`/api/v1/super_admin/upload-excel/`,formData,  
          
                {
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },           
                }
            )             
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const bulkUploadSlice = createSlice({
    name: 'bulk-upload',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(bulkUploadApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(bulkUploadApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(bulkUploadApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const bulkUploadReducer = bulkUploadSlice.reducer


