import { useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import Style from "../styles/drawer.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { isHandleDrawer } from "../store/drawer";
import { logoutApi } from "../store/auth/login";
import MobileDrawer from "./MobileDrawer";
import WebDrawer from "./WebDrawer";


export default function Drawer() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const {result:isHamburgerOpen}= useSelector((state) => state.drawerTypeReducer);
  const expandIconStyle = {ml: 7}; 
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
    if (isExpanded) {
      localStorage.setItem("expandedAccordion", panel);
    } else {
      localStorage.removeItem("expandedAccordion");
    }
  };  
  const closeHamburger = () => {
    dispatch(isHandleDrawer(!isHamburgerOpen))
  }

  const handleLogout = async () => {
    const newState = false;
    dispatch(isHandleDrawer(newState))
    dispatch(logoutApi())
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    const segments = pathname.split("/").filter(Boolean);
    const firstSegment = segments[0];
    let accordionToExpand = null;
    switch (firstSegment) {
      case undefined:
        accordionToExpand = "dealerAccordion";
        break;
      case "v":
        accordionToExpand = "phaseControllerAccordion";
        break;
      case "master":
      case "department":
      case "organization":
        accordionToExpand = "masterManagementAccordion";
        break;
      case "layout":
        accordionToExpand = "layoutManagementAccordion";
        break;
      case "users":
      case "kin":
      case "innoceans":
      case "supplier":
      case "dealer":
        accordionToExpand = "userManagementAccordion";
        break;
      case "order":
      case "orders":
        accordionToExpand = "orderMasteringAccordion";
        break;
      default:
        accordionToExpand = null;
    }
    setExpandedAccordion(accordionToExpand);
    localStorage.setItem("expandedAccordion", accordionToExpand);
  }, [pathname]);

  useEffect(() => {
    const savedAccordion = localStorage.getItem("expandedAccordion");
    if (savedAccordion) {
      setExpandedAccordion(savedAccordion);
    }
  }, []);

  return (
    <>
      {isMobile ? (
        <MobileDrawer isHamburgerOpen ={isHamburgerOpen} Style={Style} closeHamburger={closeHamburger} expandedAccordion={expandedAccordion}  handleAccordionChange={handleAccordionChange} navigate={navigate} handleLogout={handleLogout}/>
      ) : (
        <WebDrawer Style={Style} expandedAccordion={expandedAccordion}  handleAccordionChange={handleAccordionChange} navigate={navigate} handleLogout={handleLogout}/>
      )}
    
    </>    
  );
}
