import { Box, Card, Typography } from "@mui/material"

const Boxlayout = ({setSelectedTab, selectedTab}) => {

    return (<>
                         <Box className="d-flex flex-wrap  bg-white" sx={{gap:"12px",padding:"16px !important"}}>
                  <Card className={`text-center d-flex layout-sidecard-panel-btn ${selectedTab === 1 ? "active" : ""}`} sx={{background:"#D7F8EF" , borderColor:"#D7F8EF !important"}}  onClick={() => setSelectedTab(1)}>
                    <Box className="m-auto">
                      <svg width="36" height="27" viewBox="0 0 36 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M32.7857 0H32.1429C31.7878 0 31.5 0.287394 31.5 0.642551V2.5702H4.5V0.642551C4.5 0.287394 4.21216 0 3.85714 0H3.21429C2.363 0 1.55661 0.332571 0.936977 0.943116C0.33273 1.55617 0 2.3625 0 3.21275V23.7744C0 25.5458 1.44203 26.9871 3.21429 26.9871H27.6429C28.4848 26.9687 28.4919 25.72 27.6428 25.702C27.6429 25.702 3.21429 25.702 3.21429 25.702C0.675675 25.6341 0.667144 21.913 3.21435 21.8467C3.21429 21.8467 3.85714 21.8467 3.85714 21.8467C4.21216 21.8467 4.5 21.5593 4.5 21.2042V8.99571C4.5 8.64055 4.21216 8.35316 3.85714 8.35316C3.50212 8.35316 3.21429 8.64055 3.21429 8.99571V20.5616C2.51218 20.5616 1.84719 20.799 1.28571 21.2209V3.21275C1.26335 2.17846 2.18353 1.26155 3.2143 1.28512C3.21428 1.2851 3.21429 5.78296 3.21429 5.78296C3.2255 6.62987 4.49264 6.61976 4.50001 5.78292C4.50001 5.78296 4.50001 3.8553 4.50001 3.8553H31.5V21.2042C31.5 21.5593 31.7878 21.8467 32.1429 21.8467H32.7857C33.8492 21.8467 34.7143 22.7114 34.7143 23.7744C34.7368 24.8088 33.8163 25.7255 32.7857 25.702C32.7857 25.702 30.8571 25.702 30.8571 25.702C30.0083 25.7136 30.0167 26.9763 30.8572 26.9871C32.2481 26.9747 34.0037 27.2175 35.063 26.0439C35.6673 25.4309 36 24.6246 36 23.7744V18.634C35.9785 17.7842 34.7317 17.7918 34.7143 18.634C34.7143 18.634 34.7143 21.2206 34.7143 21.2206C34.1754 20.8129 33.5121 20.5616 32.7857 20.5616V1.2851C33.8492 1.2851 34.7143 2.14978 34.7143 3.21275V15.4212C34.721 16.2623 35.99 16.2667 36 15.4212C36 15.4212 36 3.21275 36 3.21275C36 1.44134 34.558 0 32.7857 0Z" fill="#5B6770"/>
                      <path d="M8.66667 8C8.2985 8 8 8.28463 8 8.63636V21.3636C8 21.7154 8.2985 22 8.66667 22H27.3333C27.7015 22 28 21.7154 28 21.3636V8.63636C28 8.28463 27.7015 8 27.3333 8H8.66667ZM9.33333 9.27273H15.3333V13.0909H9.33333V9.27273ZM26.6667 20.7273H20.6667V16.9091H26.6667V20.7273ZM26.6667 15.6364H20C19.6318 15.6364 19.3333 15.921 19.3333 16.2727V20.7273H9.33333V14.3636H16C16.3682 14.3636 16.6667 14.079 16.6667 13.7273V9.27273H26.6667V15.6364Z" fill="#5B6770"/>
                      </svg>
                      <Typography variant="h6" className="fs-13 text-center">Input Drawing</Typography>
                    </Box>
                  </Card>
                  <Card className={`text-center d-flex layout-sidecard-panel-btn ${selectedTab === 2 ? "active" : ""}`} sx={{background:"#FFF7F7" , borderColor:"#FFE4E4 !important"}}  onClick={() => setSelectedTab(2)}>
                    <Box className="m-auto">
                      <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M29.8681 0H0V29.8681H17.8441V30.5793H18.733V28.2681H17.8441V28.9792H17.1528V27.2621H14.5785V23.4397H12.4184V17.7801H13.1295V16.8911H10.8183V17.7801H11.5295V26.4177H8.48931V28.9792H0.888933V18.0172H6.10412V18.7284H6.99306V16.4172H6.10412V17.1283H0.888933V12.0909H6.10412V12.8316H6.99306V10.5203H6.10412V11.202H0.888933V9.77968H3.94082V3.61653H11.5295V12.0909H22.1639V12.8316H23.0529V10.5203H22.1639V11.202H20.4899V9.3946H16.2636V11.202H12.4184V8.35739H14.821V2.7276H12.4184V0.888933H17.9849V4.68325H28.9792V17.1283H18.8738V16.4172H17.9849V18.7284H18.8738V18.0172H28.9792V19.6767H26.4308V26.9066H28.9792V28.9792H24.9495V28.2681H24.0606V30.5793H24.9495V29.8681H29.8681V0ZM13.6896 24.3287V28.1511H16.2636V28.9792H12.4184V24.3287H13.6896ZM9.37824 27.3066H11.5295V28.9792H9.37824V27.3066ZM17.1525 10.2835H19.601V11.202H17.1525V10.2835ZM13.9324 3.61653V7.46846H12.4187V3.61653H13.9324ZM11.5295 2.7276H3.05188V8.89075H0.888933V6.28333H2.10393V5.3944H0.888933V0.888933H5.46694V1.74978H6.35587V0.888933H11.5295V2.7276ZM18.8738 3.79432V0.888933H28.9792V3.79432H18.8738ZM27.3197 26.0176V20.5656H28.9792V26.0176H27.3197Z" fill="#5B6770"/>
                      <path d="M1.66258 22.047V26.4014C1.66258 27.4795 2.53978 28.357 3.61823 28.357C4.69669 28.357 5.57389 27.4795 5.57389 26.4014V22.047C5.57389 20.9689 4.69669 20.0914 3.61823 20.0914C2.53978 20.0914 1.66258 20.9689 1.66258 22.047ZM4.68495 22.047V26.4014C4.68495 26.9895 4.20635 27.4681 3.61823 27.4681C3.03012 27.4681 2.55152 26.9895 2.55152 26.4014V22.047C2.55152 21.4589 3.03012 20.9803 3.61823 20.9803C4.20635 20.9803 4.68495 21.4589 4.68495 22.047Z" fill="#5B6770"/>
                      </svg>
                      <Typography variant="h6" className="fs-13 text-center">Design Estimates</Typography>
                    </Box>
                  </Card>
                  <Card className={`text-center d-flex layout-sidecard-panel-btn ${selectedTab === 3 ? "active" : ""}`} sx={{background:"#FAF1FF" , borderColor:"#F5E3FF !important"}} onClick={() => setSelectedTab(3)}>
                    <Box className="m-auto">
                      <svg width="41" height="32" viewBox="0 0 41 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M32.7857 0H32.1429C31.7878 0 31.5 0.287394 31.5 0.642551V2.5702H4.5V0.642551C4.5 0.287394 4.21216 0 3.85714 0H3.21429C2.363 0 1.55661 0.332571 0.936977 0.943116C0.33273 1.55617 0 2.3625 0 3.21275V23.7744C0 25.5458 1.44203 26.9871 3.21429 26.9871H27.6429C28.4848 26.9687 28.4919 25.72 27.6428 25.702C27.6429 25.702 3.21429 25.702 3.21429 25.702C0.675675 25.6341 0.667144 21.913 3.21435 21.8467C3.21429 21.8467 3.85714 21.8467 3.85714 21.8467C4.21216 21.8467 4.5 21.5593 4.5 21.2042V8.99571C4.5 8.64055 4.21216 8.35316 3.85714 8.35316C3.50212 8.35316 3.21429 8.64055 3.21429 8.99571V20.5616C2.51218 20.5616 1.84719 20.799 1.28571 21.2209V3.21275C1.26335 2.17846 2.18353 1.26155 3.2143 1.28512C3.21428 1.2851 3.21429 5.78296 3.21429 5.78296C3.2255 6.62987 4.49264 6.61976 4.50001 5.78292C4.50001 5.78296 4.50001 3.8553 4.50001 3.8553H31.5V21.2042C31.5 21.5593 31.7878 21.8467 32.1429 21.8467H32.7857C33.8492 21.8467 34.7143 22.7114 34.7143 23.7744C34.7368 24.8088 33.8163 25.7255 32.7857 25.702C32.7857 25.702 30.8571 25.702 30.8571 25.702C30.0083 25.7136 30.0167 26.9763 30.8572 26.9871C32.2481 26.9747 34.0037 27.2175 35.063 26.0439C35.6673 25.4309 36 24.6246 36 23.7744V18.634C35.9785 17.7842 34.7317 17.7918 34.7143 18.634C34.7143 18.634 34.7143 21.2206 34.7143 21.2206C34.1754 20.8129 33.5121 20.5616 32.7857 20.5616V1.2851C33.8492 1.2851 34.7143 2.14978 34.7143 3.21275V15.4212C34.721 16.2623 35.99 16.2667 36 15.4212C36 15.4212 36 3.21275 36 3.21275C36 1.44134 34.558 0 32.7857 0Z" fill="#5B6770"/>
                      <path d="M8.66667 8C8.2985 8 8 8.28463 8 8.63636V21.3636C8 21.7154 8.2985 22 8.66667 22H27.3333C27.7015 22 28 21.7154 28 21.3636V8.63636C28 8.28463 27.7015 8 27.3333 8H8.66667ZM9.33333 9.27273H15.3333V13.0909H9.33333V9.27273ZM26.6667 20.7273H20.6667V16.9091H26.6667V20.7273ZM26.6667 15.6364H20C19.6318 15.6364 19.3333 15.921 19.3333 16.2727V20.7273H9.33333V14.3636H16C16.3682 14.3636 16.6667 14.079 16.6667 13.7273V9.27273H26.6667V15.6364Z" fill="#5B6770"/>
                      <circle cx="31" cy="22" r="9.5" fill="#F1F8FF" stroke="#5B6770"/>
                      <path d="M32.4897 18.4764L34.4912 20.4779L34.9688 20.0003L32.9673 17.9988L32.4897 18.4764Z" fill="#5B6770"/>
                      <path d="M27.1613 23.8111L29.1625 25.8128L34.1442 20.8326L32.143 18.8308L27.1613 23.8111Z" fill="#5B6770"/>
                      <path d="M35.759 19.2355C36.0806 18.9138 36.0806 18.3992 35.759 18.0775L34.9227 17.2412C34.601 16.9196 34.0542 16.9196 33.7647 17.2412L33.3465 17.6594L35.373 19.6858L35.759 19.2355Z" fill="#5B6770"/>
                      <path d="M26.0126 26.6336C25.9805 26.7301 26.0126 26.8266 26.0769 26.8909C26.1091 26.9231 26.1734 26.9553 26.2378 26.9553C26.2699 26.9553 26.3021 26.9553 26.3343 26.9553L28.7467 26.0868L26.8811 24.2212L26.0126 26.6336Z" fill="#5B6770"/>
                      </svg>
                      <Typography variant="h6" className="fs-13 text-center">Schematic Layout</Typography>
                    </Box>
                  </Card>
                  <Card className={`text-center d-flex layout-sidecard-panel-btn ${selectedTab === 4 ? "active" : ""}`} sx={{background:"#FFF7F7" , borderColor:"#FFE4E4 !important"}}  onClick={() => setSelectedTab(4)}>
                    <Box className="m-auto">
                      <svg width="41" height="36" viewBox="0 0 41 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M29.8681 0H0V29.8681H17.8441V30.5793H18.733V28.2681H17.8441V28.9792H17.1528V27.2621H14.5785V23.4397H12.4184V17.7801H13.1295V16.8911H10.8183V17.7801H11.5295V26.4177H8.48931V28.9792H0.888933V18.0172H6.10412V18.7284H6.99306V16.4172H6.10412V17.1283H0.888933V12.0909H6.10412V12.8316H6.99306V10.5203H6.10412V11.202H0.888933V9.77968H3.94082V3.61653H11.5295V12.0909H22.1639V12.8316H23.0529V10.5203H22.1639V11.202H20.4899V9.3946H16.2636V11.202H12.4184V8.35739H14.821V2.7276H12.4184V0.888933H17.9849V4.68325H28.9792V17.1283H18.8738V16.4172H17.9849V18.7284H18.8738V18.0172H28.9792V19.6767H26.4308V26.9066H28.9792V28.9792H24.9495V28.2681H24.0606V30.5793H24.9495V29.8681H29.8681V0ZM13.6896 24.3287V28.1511H16.2636V28.9792H12.4184V24.3287H13.6896ZM9.37824 27.3066H11.5295V28.9792H9.37824V27.3066ZM17.1525 10.2835H19.601V11.202H17.1525V10.2835ZM13.9324 3.61653V7.46846H12.4187V3.61653H13.9324ZM11.5295 2.7276H3.05188V8.89075H0.888933V6.28333H2.10393V5.3944H0.888933V0.888933H5.46694V1.74978H6.35587V0.888933H11.5295V2.7276ZM18.8738 3.79432V0.888933H28.9792V3.79432H18.8738ZM27.3197 26.0176V20.5656H28.9792V26.0176H27.3197Z" fill="#5B6770"/>
                      <path d="M1.66258 22.047V26.4014C1.66258 27.4795 2.53978 28.357 3.61823 28.357C4.69669 28.357 5.57389 27.4795 5.57389 26.4014V22.047C5.57389 20.9689 4.69669 20.0914 3.61823 20.0914C2.53978 20.0914 1.66258 20.9689 1.66258 22.047ZM4.68495 22.047V26.4014C4.68495 26.9895 4.20635 27.4681 3.61823 27.4681C3.03012 27.4681 2.55152 26.9895 2.55152 26.4014V22.047C2.55152 21.4589 3.03012 20.9803 3.61823 20.9803C4.20635 20.9803 4.68495 21.4589 4.68495 22.047Z" fill="#5B6770"/>
                      <circle cx="30.5" cy="25.5" r="10" fill="#F9F9F9" stroke="#5B6770"/>
                      <path d="M34.2703 20.5811L33.4054 21.4459C31.5003 23.3551 30.0316 24.956 28.2862 26.7432L26.5119 25.2424L25.5771 24.4539L24 26.3171L24.9348 27.1057L27.5803 29.3442L28.4388 30.0691L29.2337 29.2742C31.4296 27.0736 32.9897 25.3194 35.1351 23.1693L36 22.3044L34.2703 20.5811Z" fill="#5B6770"/>
                      </svg>
                      <Typography variant="h6" className="fs-13 text-center">Schematic Revised Layout</Typography>
                    </Box>
                  </Card>
                  <Card className={`text-center d-flex layout-sidecard-panel-btn ${selectedTab === 5 ? "active" : ""}`} sx={{background:"#F1F8FF" , borderColor:"#E0EFFF !important"}}  onClick={() => setSelectedTab(5)}>
                    <Box className="m-auto">
                      <svg width="41" height="36" viewBox="0 0 41 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M29.8681 0H0V29.8681H17.8441V30.5793H18.733V28.2681H17.8441V28.9792H17.1528V27.2621H14.5785V23.4397H12.4184V17.7801H13.1295V16.8911H10.8183V17.7801H11.5295V26.4177H8.48931V28.9792H0.888933V18.0172H6.10412V18.7284H6.99306V16.4172H6.10412V17.1283H0.888933V12.0909H6.10412V12.8316H6.99306V10.5203H6.10412V11.202H0.888933V9.77968H3.94082V3.61653H11.5295V12.0909H22.1639V12.8316H23.0529V10.5203H22.1639V11.202H20.4899V9.3946H16.2636V11.202H12.4184V8.35739H14.821V2.7276H12.4184V0.888933H17.9849V4.68325H28.9792V17.1283H18.8738V16.4172H17.9849V18.7284H18.8738V18.0172H28.9792V19.6767H26.4308V26.9066H28.9792V28.9792H24.9495V28.2681H24.0606V30.5793H24.9495V29.8681H29.8681V0ZM13.6896 24.3287V28.1511H16.2636V28.9792H12.4184V24.3287H13.6896ZM9.37824 27.3066H11.5295V28.9792H9.37824V27.3066ZM17.1525 10.2835H19.601V11.202H17.1525V10.2835ZM13.9324 3.61653V7.46846H12.4187V3.61653H13.9324ZM11.5295 2.7276H3.05188V8.89075H0.888933V6.28333H2.10393V5.3944H0.888933V0.888933H5.46694V1.74978H6.35587V0.888933H11.5295V2.7276ZM18.8738 3.79432V0.888933H28.9792V3.79432H18.8738ZM27.3197 26.0176V20.5656H28.9792V26.0176H27.3197Z" fill="#5B6770"/>
                      <path d="M1.66258 22.047V26.4014C1.66258 27.4795 2.53978 28.357 3.61823 28.357C4.69669 28.357 5.57389 27.4795 5.57389 26.4014V22.047C5.57389 20.9689 4.69669 20.0914 3.61823 20.0914C2.53978 20.0914 1.66258 20.9689 1.66258 22.047ZM4.68495 22.047V26.4014C4.68495 26.9895 4.20635 27.4681 3.61823 27.4681C3.03012 27.4681 2.55152 26.9895 2.55152 26.4014V22.047C2.55152 21.4589 3.03012 20.9803 3.61823 20.9803C4.20635 20.9803 4.68495 21.4589 4.68495 22.047Z" fill="#5B6770"/>
                      <circle cx="30.5" cy="25.5" r="10" fill="#F9F9F9" stroke="#5B6770"/>
                      <path d="M34.2703 20.5811L33.4054 21.4459C31.5003 23.3551 30.0316 24.956 28.2862 26.7432L26.5119 25.2424L25.5771 24.4539L24 26.3171L24.9348 27.1057L27.5803 29.3442L28.4388 30.0691L29.2337 29.2742C31.4296 27.0736 32.9897 25.3194 35.1351 23.1693L36 22.3044L34.2703 20.5811Z" fill="#5B6770"/>
                      </svg>
                      <Typography variant="h6" className="fs-13 text-center">GFC</Typography>
                    </Box>
                  </Card>
                  <Card className={`text-center d-flex layout-sidecard-panel-btn ${selectedTab === 6 ? "active" : ""}`} sx={{background:"#FFF7F7" , borderColor:"#FFE4E4 !important"}}  onClick={() => setSelectedTab(6)}>
                    <Box className="m-auto">
                      <svg width="41" height="36" viewBox="0 0 41 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M29.8681 0H0V29.8681H17.8441V30.5793H18.733V28.2681H17.8441V28.9792H17.1528V27.2621H14.5785V23.4397H12.4184V17.7801H13.1295V16.8911H10.8183V17.7801H11.5295V26.4177H8.48931V28.9792H0.888933V18.0172H6.10412V18.7284H6.99306V16.4172H6.10412V17.1283H0.888933V12.0909H6.10412V12.8316H6.99306V10.5203H6.10412V11.202H0.888933V9.77968H3.94082V3.61653H11.5295V12.0909H22.1639V12.8316H23.0529V10.5203H22.1639V11.202H20.4899V9.3946H16.2636V11.202H12.4184V8.35739H14.821V2.7276H12.4184V0.888933H17.9849V4.68325H28.9792V17.1283H18.8738V16.4172H17.9849V18.7284H18.8738V18.0172H28.9792V19.6767H26.4308V26.9066H28.9792V28.9792H24.9495V28.2681H24.0606V30.5793H24.9495V29.8681H29.8681V0ZM13.6896 24.3287V28.1511H16.2636V28.9792H12.4184V24.3287H13.6896ZM9.37824 27.3066H11.5295V28.9792H9.37824V27.3066ZM17.1525 10.2835H19.601V11.202H17.1525V10.2835ZM13.9324 3.61653V7.46846H12.4187V3.61653H13.9324ZM11.5295 2.7276H3.05188V8.89075H0.888933V6.28333H2.10393V5.3944H0.888933V0.888933H5.46694V1.74978H6.35587V0.888933H11.5295V2.7276ZM18.8738 3.79432V0.888933H28.9792V3.79432H18.8738ZM27.3197 26.0176V20.5656H28.9792V26.0176H27.3197Z" fill="#5B6770"/>
                      <path d="M1.66258 22.047V26.4014C1.66258 27.4795 2.53978 28.357 3.61823 28.357C4.69669 28.357 5.57389 27.4795 5.57389 26.4014V22.047C5.57389 20.9689 4.69669 20.0914 3.61823 20.0914C2.53978 20.0914 1.66258 20.9689 1.66258 22.047ZM4.68495 22.047V26.4014C4.68495 26.9895 4.20635 27.4681 3.61823 27.4681C3.03012 27.4681 2.55152 26.9895 2.55152 26.4014V22.047C2.55152 21.4589 3.03012 20.9803 3.61823 20.9803C4.20635 20.9803 4.68495 21.4589 4.68495 22.047Z" fill="#5B6770"/>
                      <circle cx="30.5" cy="25.5" r="10" fill="#F9F9F9" stroke="#5B6770"/>
                      <path d="M34.2703 20.5811L33.4054 21.4459C31.5003 23.3551 30.0316 24.956 28.2862 26.7432L26.5119 25.2424L25.5771 24.4539L24 26.3171L24.9348 27.1057L27.5803 29.3442L28.4388 30.0691L29.2337 29.2742C31.4296 27.0736 32.9897 25.3194 35.1351 23.1693L36 22.3044L34.2703 20.5811Z" fill="#5B6770"/>
                      </svg>
                      <Typography variant="h6" className="fs-13 text-center">GFC Revised Layout</Typography>
                    </Box>
                  </Card>
                  <Card className={`text-center d-flex layout-sidecard-panel-btn ${selectedTab === 7 ? "active" : ""}`} sx={{background:"#FFF1E7" , borderColor:"#FEE6D6 !important"}}  onClick={() => setSelectedTab(7)}>
                    <Box className="m-auto">
                      <svg width="41" height="36" viewBox="0 0 41 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M29.8681 0H0V29.8681H17.8441V30.5793H18.733V28.2681H17.8441V28.9792H17.1528V27.2621H14.5785V23.4397H12.4184V17.7801H13.1295V16.8911H10.8183V17.7801H11.5295V26.4177H8.48931V28.9792H0.888933V18.0172H6.10412V18.7284H6.99306V16.4172H6.10412V17.1283H0.888933V12.0909H6.10412V12.8316H6.99306V10.5203H6.10412V11.202H0.888933V9.77968H3.94082V3.61653H11.5295V12.0909H22.1639V12.8316H23.0529V10.5203H22.1639V11.202H20.4899V9.3946H16.2636V11.202H12.4184V8.35739H14.821V2.7276H12.4184V0.888933H17.9849V4.68325H28.9792V17.1283H18.8738V16.4172H17.9849V18.7284H18.8738V18.0172H28.9792V19.6767H26.4308V26.9066H28.9792V28.9792H24.9495V28.2681H24.0606V30.5793H24.9495V29.8681H29.8681V0ZM13.6896 24.3287V28.1511H16.2636V28.9792H12.4184V24.3287H13.6896ZM9.37824 27.3066H11.5295V28.9792H9.37824V27.3066ZM17.1525 10.2835H19.601V11.202H17.1525V10.2835ZM13.9324 3.61653V7.46846H12.4187V3.61653H13.9324ZM11.5295 2.7276H3.05188V8.89075H0.888933V6.28333H2.10393V5.3944H0.888933V0.888933H5.46694V1.74978H6.35587V0.888933H11.5295V2.7276ZM18.8738 3.79432V0.888933H28.9792V3.79432H18.8738ZM27.3197 26.0176V20.5656H28.9792V26.0176H27.3197Z" fill="#5B6770"/>
                      <path d="M1.66258 22.047V26.4014C1.66258 27.4795 2.53978 28.357 3.61823 28.357C4.69669 28.357 5.57389 27.4795 5.57389 26.4014V22.047C5.57389 20.9689 4.69669 20.0914 3.61823 20.0914C2.53978 20.0914 1.66258 20.9689 1.66258 22.047ZM4.68495 22.047V26.4014C4.68495 26.9895 4.20635 27.4681 3.61823 27.4681C3.03012 27.4681 2.55152 26.9895 2.55152 26.4014V22.047C2.55152 21.4589 3.03012 20.9803 3.61823 20.9803C4.20635 20.9803 4.68495 21.4589 4.68495 22.047Z" fill="#5B6770"/>
                      <circle cx="30.5" cy="25.5" r="10" fill="#F9F9F9" stroke="#5B6770"/>
                      <path d="M34.2703 20.5811L33.4054 21.4459C31.5003 23.3551 30.0316 24.956 28.2862 26.7432L26.5119 25.2424L25.5771 24.4539L24 26.3171L24.9348 27.1057L27.5803 29.3442L28.4388 30.0691L29.2337 29.2742C31.4296 27.0736 32.9897 25.3194 35.1351 23.1693L36 22.3044L34.2703 20.5811Z" fill="#5B6770"/>
                      </svg>
                      <Typography variant="h6" className="fs-13 text-center">Feedback
                      Received</Typography>
                    </Box>
                  </Card>
                </Box>
    </>)
}
export default Boxlayout