import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box, Card, IconButton, Modal, Paper, Stack, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { default as React } from 'react';


export default function OrderDetails({ open, setOpen }) {

    const columns = [
        {
            field: 'category',
            headerName: 'Category',
            width: 150
        },
        {
            field: 'supplier',
            headerName: 'Supplier',
            width: 150
        },
        {
            field: 'product',
            headerName: 'Product',
            width: 150
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            width: 150
        },
        {
            field: 'price',
            headerName: 'Price',
            width: 150
        },
    ]

    const handleClose = () => setOpen(false)


    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <Card className='modal'>
                    <Stack spacing={2}>
                        <Stack alignItems='flex-end'><IconButton onClick={handleClose}><HighlightOffIcon /></IconButton></Stack>
                        <Stack direction='row'>
                            <Typography variant='h5'>Order ID:</Typography>
                            <Typography variant='h5' color='#2073B0'>AKN12598</Typography>
                        </Stack>

                        <Stack direction='row' spacing={2}>
                            <Stack direction='row'>
                                <Typography fontWeight={700}>Purchase Order Date:</Typography>
                                <Typography>7/19/23</Typography>
                            </Stack>

                            <Box className='divider3'></Box>

                            <Stack direction='row'>
                                <Typography fontWeight={700}>Payment Date:</Typography>
                                <Typography>7/19/23</Typography>
                            </Stack>

                            <Box className='divider3'></Box>

                            <Stack direction='row'>
                                <Typography fontWeight={700}>Order Delivered:</Typography>
                                <Typography>7/19/23</Typography>
                            </Stack>
                        </Stack>

                        <Paper elevation={0}>
                            <DataGrid
                                sx={{ maxHeight: 500 }}
                                rows={[]}
                                columns={columns}
                                disableRowSelectionOnClick
                                hideFooter
                            />
                        </Paper>
                    </Stack>
                </Card>
            </Modal>
        </>
    )
}
