import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthAxios from '../../config/authAxios'; 

export const addDealerGroupApi = createAsyncThunk(
    'dealerGroup/add-dealeruser', 
    async (formData, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post('/api/v1/dealor/dealer-group', formData, {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },           
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

export const editDealerGroupApi = createAsyncThunk(
    'dealerGroup/edit-dealeruser',
    async ( formData, { rejectWithValue }) => {
        try {
            let id = localStorage.getItem("selectedId")
            const response = await AuthAxios.patch(`/api/v1/dealor/dealer-group?id=${id}`,formData,          
                {
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },           
                }
            )   
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const deleteDealerGroupApi = createAsyncThunk(
    'dealerGroup/delete-dealeruser',
    async (idToDelete, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.delete(`/api/v1/dealor/dealer-group?id=${idToDelete}`,
                {
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },
                }
            )
            return response?.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);

const addDealerGroupSlice = createSlice({
    name: 'add-dealeruser',
    initialState: { result: [], loading: false, error: null, message: "" },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(addDealerGroupApi.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addDealerGroupApi.fulfilled, (state, action) => {
            state.loading = false;
            state.kinUserData = action.payload;
            state.error = null;
        });
        builder.addCase(addDealerGroupApi.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        

        builder.addCase(editDealerGroupApi.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(editDealerGroupApi.fulfilled, (state, action) => {
            state.loading = false;
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        });
        builder.addCase(editDealerGroupApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
            
        });


        builder.addCase(deleteDealerGroupApi.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(deleteDealerGroupApi.fulfilled, (state, action) => {
            state.loading = false;
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        });
        builder.addCase(deleteDealerGroupApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
            
        });

    }
});

export const addDealerGroupReducer = addDealerGroupSlice.reducer;
