import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"

export const getFeedback = createAsyncThunk(
    'layout/sendToKin',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post(`/api/v1/layout/get_feedback/`, data,{
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("access_token")
                }
            })
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const getFeedbackSlice = createSlice({
    name: 'sendToKin',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(getFeedback.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getFeedback.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        })
        builder.addCase(getFeedback.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
    },
})

export const getFeedbackReducer = getFeedbackSlice.reducer