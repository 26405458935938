    import {
        Box,
        Button,
        Card,
        CardHeader,
        CircularProgress,
        IconButton,
        Modal,
        Paper,
        Stack,
        Table,
        TableBody,
        TableCell,
        tableCellClasses,
        TableContainer,
        TableHead,
        TableRow,
        Typography,
        OutlinedInput,
        FormControl,
        InputAdornment,
        Grid
    } from "@mui/material";
    import React, { useEffect, useRef, useState } from "react";
    import HighlightOffIcon from "@mui/icons-material/HighlightOff";
    import ModalCss from "../../styles/modal.module.scss";
    import { makeStyles, styled } from "@mui/styles"; // Changed import to use curly braces for makeStyles
    import { useDispatch } from "react-redux";
    import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
    import { showToast } from "../../toast/toast";
    import { ordersApi, uploadQuotationApi } from "../../store/orders/orders";
    import CancelIcon from '@mui/icons-material/Cancel';
    import { v4 as uuidv4 } from 'uuid'; // Import uuid for generating unique IDs
    import RemoveIcon from '@mui/icons-material/Remove';
    import AddIcon from '@mui/icons-material/Add';
    import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
    import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
    import { DisabledByDefaultOutlined } from '@mui/icons-material';
    import CheckCircleIcon from '@mui/icons-material/CheckCircle';
    import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { GridDeleteIcon } from "@mui/x-data-grid";
    // import deletesvg from "../../assets/svg/delete.svg";

  const useStyles = makeStyles((theme) => ({
    bgLightGray: {
      backgroundColor: "rgba(213, 222, 229, 1)",
    },
    borderRadius10: {
      borderRadius: "10px",
    },
    p10: {
      padding: "10px",
    },
    p20: {
      padding: "20px",
    },
    buttonsStyle: {
      backgroundColor: "#D7EDFE",
    },
    fullWidth: {
      width: "100%",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
  
  const CreateQuotation = ({ open, handleClose, orderData }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [cartItems, setCartItems] = useState([]);
    const [currentData, setCurrentData] = useState(null);
    const [quantityLoading, setQuantityLoading] = useState(false);

    const [openModal, setOpenModal] = useState(false);
    const [subtotal, setSubtotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [purpose, setPurpose] = useState("");
    const [loading, setLoading] = useState(true);
    const [itemToDelete, setItemToDelete] = useState(null);

    const closeTheModal = () => {
        setCartItems([]);
        handleClose();
    };

    const updateQuantity = async (temp) => {
        const items = temp.map(item => ({
            id: item.id,
            product: item.product,
            quantity: item.quantity
        }));
        const requestData = {
            //id: cart_id ? cart_id : CartId,
            id:1,
            items: items
        };

        // try {
        //     setQuantityLoading(true);
        //     await dispatch(updateCartApi(requestData));
        //     setTimeout(() => {
        //         dispatch(filterCartApi({ cart_id: cart_id ? cart_id : CartId }));
        //     }, 2000)
        //     setQuantityLoading(false);
        //     showToast("Item added successfully");
        // } catch (error) {
        //     console.error("Error submitting address details:", error);
        // }
    };

    const handleDecrement = (itemId, itemQuantity) => {
        if (!loading && itemQuantity > 1) {
            const temp = cartItems.map((item) => {
                if (item.id === itemId) {
                    return { ...item, quantity: itemQuantity - 1 };
                }
                return item;
            });
            setCartItems(temp);
            updateQuantity(temp); 
        }
    };
    
    const handleIncrement = (itemId, itemQuanity) => {
        if(!loading) {
         const temp = cartItems.map((item) => {
             if (item.id === itemId) {
                 return { ...item, quantity: itemQuanity + 1 };
             }
             return item;
         });
         setCartItems(temp);
         updateQuantity(temp);
        } 
         
         
     };

    const handleUpdate = () => {
        const quantity = cartItems.find(item => item.id === currentData)?.quantity || 1;
        const newValue = Math.max(Number(quantity), 1); // Ensure the value is at least 1
        updateQuantity(cartItems);
    };

    const handleChange = (itemId, value) => {
        if(!loading) {
            const newValue = Math.max(Number(value), 1);
            const temp = cartItems.map((item) => {
                if (item.id === itemId) {
                    return { ...item, quantity: newValue };
                }
                return item;
            });
            setCartItems(temp);
            setCurrentData(itemId);
        }

    };

    const handleOpenModal = (itemId) => {
        setItemToDelete(itemId);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setItemToDelete(null);
    };

    const handleConfirmDelete = async () => {
        if (itemToDelete !== null) {
            const temp = cartItems.filter((item) => item.id !== itemToDelete);
            setCartItems(temp);
            updateQuantity(temp);
            //await deleteItem(cart_id, itemToDelete);
            handleCloseModal();
        }
    };

    useEffect(() => {
        calculateTotals(cartItems);
        setLoading(false);
    }, [cartItems]);

    const calculateTotals = (temp) => {
        let newSubtotal = 0;
        temp?.forEach((item) => {
            const quantity = item.quantity
            newSubtotal += item.price * quantity;
        });
        newSubtotal = (Math.floor(newSubtotal * 100) / 100).toFixed(2);
        setSubtotal(newSubtotal);
        setTotal(newSubtotal);
    };

    useEffect(() => {
        setCartItems(orderData?.[0]?.order_items);
    },[orderData])
    return (
        <>
      <Modal open={open} onClose={() => closeTheModal()} className={ModalCss.customModal}>
    <Card className={`modal ${ModalCss.customModal_md}`} sx={{ maxWidth: '90%', width: '90%' }}>
        <CardHeader sx={{ textAlign: "center" }} title="Confirm Order" />
        <Typography component="div" sx={{ textAlign: "center", marginTop: 2, marginBottom: 2 }}>
             You can update the cart item(s) quantity as per GFC*
        </Typography>

        <Stack alignItems="flex-end" position={"absolute"} right={5} top={5}>
            <IconButton onClick={() => closeTheModal()}>
                <HighlightOffIcon />
            </IconButton>
        </Stack>

        <Grid container spacing={2}>
            <Grid item xs={12} className="d-flex">
                <TableContainer className='complex-custom-table' sx={{ borderRadius: "10px 10px 0 0" }}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell sx={{ background: "#05141F !important", color: "white" }}>Category</StyledTableCell>
                                <StyledTableCell sx={{ background: "#05141F !important", color: "white" }} align="center">Supplier</StyledTableCell>
                                <StyledTableCell sx={{ background: "#05141F !important", color: "white" }} align="center">Product</StyledTableCell>
                                <StyledTableCell sx={{ background: "#05141F !important", color: "white" }} align="center">Quantity</StyledTableCell>
                                <StyledTableCell sx={{ background: "#05141F !important", color: "white" }} align="center">Price (₹)</StyledTableCell>
                                <StyledTableCell sx={{ background: "#05141F !important", color: "white" }} align="center"></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {quantityLoading ? (
                                <StyledTableRow>
                                    <StyledTableCell colSpan={6} align="center">
                                        <CircularProgress />
                                    </StyledTableCell>
                                </StyledTableRow>
                            ) : cartItems?.map((item) => (
                                <StyledTableRow key={item.id}>
                                    <StyledTableCell align="center">{item.category}</StyledTableCell>
                                    <StyledTableCell align="center">{item.supplier}</StyledTableCell>
                                    <StyledTableCell align="center" className="inner-table-cell">
                                        <Box className="inner-table">
                                            <Typography variant='h4' className='fs-14 fw-300'>
                                                <span style={{ width: "100%", maxHeight: "100%", overflow: "auto" }}>{item.product_description}</span>
                                            </Typography>
                                        </Box>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" className="inner-table-cell">
                                        <Box className="inner-table">
                                            <Box className='container-inner-table'>
                                                <span className='m-auto d-flex gap-10'>
                                                    <Button onClick={() => handleDecrement(item.id, item?.quantity)}><RemoveIcon /></Button>
                                                    <OutlinedInput
                                                        value={item?.quantity}
                                                        onChange={(e) => handleChange(item.id, e.target.value)}
                                                        onBlur={handleUpdate}
                                                    />
                                                    <Button onClick={() => handleIncrement(item.id, item?.quantity )}><AddIcon /></Button>
                                                </span>
                                            </Box>
                                        </Box>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" className="inner-table-cell">
                                        <Box className="inner-table">
                                            <Typography variant='h4' className='fs-14 fw-300'><span> {item.price}</span></Typography>
                                        </Box>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" className="inner-table-cell">
                                        <Typography variant='h4' className='fs-14 fw-300'>
                                            <Button className='fs-14 fw-300 delete-btn' onClick={() => handleOpenModal(item.id)}>
                                                <GridDeleteIcon/>
                                            </Button>
                                        </Typography>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                            <StyledTableRow key={"subtotal"}>
                                <StyledTableCell colSpan={3}></StyledTableCell>
                                <StyledTableCell colSpan={3}>
                                    <Box className="flex-wrap gap-30 justify-center subtotal">
                                        <Typography variant='h4' className='fs-15 fw-300'>Subtotal</Typography>
                                        <Typography variant='h4' className='fs-15 fw-600'>₹ {subtotal}</Typography>
                                    </Box>
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell colSpan={3}></StyledTableCell>
                                <StyledTableCell colSpan={3}>
                                    <Box className="flex-wrap gap-30 justify-center total">
                                        <Typography variant='h4' className='fs-15 fw-300'>Total</Typography>
                                        <Typography variant='h4' className='fs-15 fw-600'>₹ {total} (*Taxes Excluded)</Typography>
                                    </Box>
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12} className="flex-wrap justify-content-between">
                <Box className="gap-10 flex-wrap justify-content-center">
                    {/* <Button className="back-btn" onClick={() => navigate(-1)}>Back</Button>
                    <Button className="white-btn" onClick={() => navigate("/order/material-selection")}>Add More material</Button> */}
                </Box>
                <Box className="gap-10 flex-wrap justify-content-center">
                    <Button className="link-btn-underline" onClick={closeTheModal}>Cancel</Button>
                    <Button className="black-btn">Next</Button>
                </Box>
            </Grid>
        </Grid>
    </Card>
</Modal>

        <DeleteConfirmationModal open={openModal} handleClose={handleCloseModal}
                handleConfirm={handleConfirmDelete}
                deleteType='Dealer' />
        </>
    );
  };
  
  export default CreateQuotation;
  