import { Button } from '@mui/material';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import ButtonStyles from "../styles/buttons.module.scss";


const ExportExcel = ({ excelData, fileName }) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;chartset=UTF-8';
    const fileExtension = '.xlsx';
    
    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(excelData);
        
        // Apply bold style to the header row
        const header = ws['!ref'].split(':')[0].replace('1', ''); // Get the first cell's column letter (e.g., 'A')
        const range = XLSX.utils.decode_range(ws['!ref']);
        for(let C = range.s.c; C <= range.e.c; ++C) {
            const cell_address = XLSX.utils.encode_col(C) + "1";
            if (!ws[cell_address]) continue;
            ws[cell_address].s = {
                font: {
                    bold: true
                }
            };
        }

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
            <Button
                onClick={exportToExcel}
                variant="outlined" className={ButtonStyles.download}
            >
                <svg
                    width="17"
                    height="21"
                    viewBox="0 0 17 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_17_47906)">
                        <path
                            d="M15.5832 11.375C15.4423 11.375 15.3071 11.4303 15.2075 11.5288C15.1079 11.6272 15.0519 11.7608 15.0519 11.9V14C15.0515 14.232 14.9581 14.4543 14.7922 14.6183C14.6262 14.7823 14.4012 14.8746 14.1665 14.875H2.83317C2.59846 14.8746 2.37347 14.7823 2.2075 14.6183C2.04153 14.4543 1.94813 14.232 1.94775 14V11.9C1.94775 11.7608 1.89178 11.6272 1.79215 11.5288C1.69253 11.4303 1.5574 11.375 1.4165 11.375C1.27561 11.375 1.14048 11.4303 1.04085 11.5288C0.941225 11.6272 0.885254 11.7608 0.885254 11.9V14C0.885629 14.5104 1.09098 14.9998 1.4562 15.3608C1.82142 15.7217 2.31667 15.9246 2.83317 15.925H14.1665C14.683 15.9246 15.1783 15.7217 15.5435 15.3608C15.9087 14.9998 16.114 14.5104 16.1144 14V11.9C16.1144 11.7608 16.0585 11.6272 15.9588 11.5288C15.8592 11.4303 15.7241 11.375 15.5832 11.375Z"
                            fill="black"
                        />
                        <path
                            d="M8.49997 0.875C8.35908 0.875 8.22395 0.930312 8.12432 1.02877C8.02469 1.12723 7.96872 1.26076 7.96872 1.4V10.08L4.58997 7.2968C4.48133 7.21161 4.34328 7.17178 4.20532 7.18583C4.06737 7.19988 3.94044 7.26668 3.85165 7.37197C3.76286 7.47726 3.71926 7.61269 3.73016 7.74932C3.74106 7.88595 3.80559 8.01296 3.90997 8.1032L8.15997 11.6032C8.18571 11.6215 8.21324 11.6372 8.24214 11.6501C8.26306 11.6636 8.28483 11.6757 8.3073 11.6865C8.36828 11.7119 8.43379 11.725 8.49997 11.725C8.56615 11.725 8.63166 11.7119 8.69264 11.6865C8.71512 11.6757 8.73688 11.6636 8.75781 11.6501C8.78671 11.6372 8.81423 11.6215 8.83997 11.6032L13.09 8.1032C13.1944 8.01296 13.2589 7.88595 13.2698 7.74932C13.2807 7.61269 13.2371 7.47726 13.1483 7.37197C13.0595 7.26668 12.9326 7.19988 12.7946 7.18583C12.6567 7.17178 12.5186 7.21161 12.41 7.2968L9.03122 10.08V1.4C9.03122 1.26076 8.97525 1.12723 8.87562 1.02877C8.77599 0.930312 8.64087 0.875 8.49997 0.875Z"
                            fill="black"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_17_47906">
                            <rect width="17" height="21" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                Download
            </Button>
    )
}

export default ExportExcel;
