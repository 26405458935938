export const isAllOrderValidationTrue = (orderItems) => {
  // Ensure orderItems is an array before proceeding
  if (!Array.isArray(orderItems)) {
    console.error("orderItems is not an array:", orderItems);
    return false;
  }

  for (let item of orderItems) {
    if (!item.order_validation) {
      return false;
    }
  }
  return true;
}


export const  isAllOrderAcceptedTrue = (orderItems) => {
  if (!Array.isArray(orderItems)) {
    console.error("orderItems is not an array:", orderItems);
    return false;
  }


  for (let item of orderItems) {
    if (!item.order_accepted) {
      return false;
    }
  }
  return true;
}