import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import pdf from '../../assets/img/pdf.png';
import cad from '../../assets/img/cad.png';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { convertToFormData } from "../ConvertToFormData";
import { gfcLayout } from "../../store/home/gfc";
import { handleDownload } from "../reusable/downloadFile";
import { approveGfcByAdmin } from "../../store/home/approveGfcByAdmin";
import { showToast } from "../../toast/toast";
import RequestForRevision from "../reusable/RequestForRevision";
import DoneIcon from '@mui/icons-material/Done';
export default function Gfc() {
    const dispatch = useDispatch();
    const formId = useSelector((state) => state?.inputDrawingByClusterReducer?.result);  
    const [open, setOpen] = useState(false);
    const {result : GFCData, loading : GFCLoading} = useSelector((state) => state?.gfcReducer);
    const {result : revisedGFCData} = useSelector((state) => state?.revisedGFCViewReducer);
    const [data, setData] = useState(localStorage.getItem("isRevisedGFCViewClick") == 1 ? revisedGFCData : GFCData);   

    useEffect(() => {
        const isRevisedGFCViewClick = localStorage.getItem("isRevisedGFCViewClick");
        setData(isRevisedGFCViewClick === '1' ? revisedGFCData : GFCData);
      }, [revisedGFCData, GFCData]);
  
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const getGfcLayout = async () => {
        const formD = {
            'form_id': formId?.data?.id
        }
        const formData = convertToFormData(formD);
        try {
            const response = await dispatch(gfcLayout(formData));
            setData(response?.payload?.data);
        } catch (error) {
            
        }
    }

    useEffect(() => {
        if (formId) {
            getGfcLayout();
        }
    }, [formId]);

    const gfcPdfExists = Array.isArray(data?.gfc_pdf) && data.gfc_pdf.length > 0;
    const gfcCadExists = Array.isArray(data?.gfc_cad) && data.gfc_cad.length > 0;

    const handleApproveGfcByAdmin = async () => {

        const formData = convertToFormData({ 'gfc_id': data.id });

        try {
            const response = await dispatch(approveGfcByAdmin(formData));
            showToast(response?.payload?.message, 1);
        } catch (error) {
            console.error("Error in sending data to Kin:", error);
        }
    };

    return (
        <Box className="h-100 d-flex flex-column justify-content-between">
            {gfcPdfExists ? (
    <>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                <Typography variant="h6" className="fs-17 fw-400">GFC Received</Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                <Box className="d-flex flex-wrap w-100" sx={{ gap: "24px" }}>
                    {gfcPdfExists && (
                        <Box sx={{ width: "213px" }}>
                            <Box className="upload-box bg-white text-center">
                                <Box className="m-auto p-relative">
                                    <Tooltip title={data.gfc_pdf[0].files} placement="top" className="uploadedfilename">
                                        <img width={40} height={40} src={pdf} className="my-auto" alt="PDF Icon" />
                                        <Typography>PDF File</Typography>
                                        <DownloadForOfflineRoundedIcon
                                            className="my-auto"
                                            onClick={() => handleDownload(new Date() + 'schematic_cad', data.gfc_pdf[0].files)}
                                        />
                                        <FullscreenIcon
                                            className="my-auto"
                                            onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${data.gfc_pdf[0].files}`)}
                                        />
                                    </Tooltip>
                                </Box>
                            </Box>
                        </Box>
                    )}

                    {gfcCadExists && (
                        <Box sx={{ width: "213px" }}>
                            <Box className="upload-box bg-white text-center">
                                <Box className="m-auto p-relative">
                                    <Tooltip title={data.gfc_cad[0].files} placement="top" className="uploadedfilename">
                                        <img width={40} height={40} src={cad} className="my-auto" alt="CAD Icon" />
                                        <Typography>CAD File</Typography>
                                        <DownloadForOfflineRoundedIcon
                                            className="my-auto"
                                            onClick={() => handleDownload(new Date() + 'schematic_cad', data.gfc_cad[0].files)}
                                        />
                                        <FullscreenIcon
                                            className="my-auto"
                                            onClick={() => window.open(`${process.env.REACT_APP_BASE_URL}${data.gfc_cad[0].files}`)}
                                        />
                                    </Tooltip>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex" sx={{ gap: "23px" }}>
                {data?.approve_by_admin ?
                    <Button className="black-btn" style={{ color: "green" }}>
                        <DoneIcon style={{ marginRight: "2px", marginTop: "-5px" }} /> Approved
                    </Button>
                    :
                    <Button className="black-btn" onClick={handleApproveGfcByAdmin}>Approve</Button>
                }
                <Button className="white-btn" onClick={handleOpen}>Request for revision</Button>
            </Grid>
        </Grid>
    </>
) : (
    <Typography variant="h6" className="no-data-found">No data found</Typography>
)}

            <RequestForRevision open={open} handleClose={handleClose} />
        </Box>
    );
}
