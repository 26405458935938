import React, { useEffect } from 'react'
import Appbar from '../../components/Appbar'
import Drawer from '../../components/Drawer'

import Body from '../../components/Body'
import { Box, Paper, Stack, Typography } from '@mui/material'
import InventoryIcon from '@mui/icons-material/Inventory';
import { DataGrid } from '@mui/x-data-grid'
import { useDispatch } from 'react-redux'
import { billInvoicesApi } from '../../store/orders/billInvoices'
import NodataImg from "../../assets/img/noData.png"


export default function BillsAndInvoices({first_name}) {

    const dispatch = useDispatch()

    const columns = [
        {
            field: 'order_id',
            headerName: 'Purchase Order No.',
            width: 250
        },
        {
            field: 'supplier',
            headerName: 'PO Date',
            width: 250
        },
        {
            field: 'product',
            headerName: 'Invoice Date',
            width: 250
        },
        {
            field: 'order_date',
            headerName: 'Download',
            width: 400
        },
    ]


    useEffect(() => {
        dispatch(billInvoicesApi())
    }, [dispatch])


    return (
        <>
            <Body>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Box className='divider1'></Box>
                    <Typography variant='h6'>{`Welcome ${first_name}`}</Typography>
                </Stack>
                <Stack direction='row' spacing={1} sx={{ my: 2 }} alignItems='center'>
                    <InventoryIcon fontSize='large' />
                    <Typography>View Bills and Invoices</Typography>
                </Stack>
                <Stack>

                </Stack>

                <Paper elevation={0}>
                    <DataGrid
                        sx={{ maxHeight: 500 }}
                        rows={[]}
                        columns={columns}
                        disableRowSelectionOnClick
                        slots={{
                            noRowsOverlay: () => <Box className='noData'><img src={NodataImg} alt='no data found image' /></Box>
                        }}
                        pagination
                        rowsPerPageOptions={[25, 50, 100]}
                    />
                </Paper>
            </Body>
        </>
    )
}
