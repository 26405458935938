import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import { useId } from "react"

    export const getKinUserApi = createAsyncThunk(
        'kin/get-kinUser',
        async (_, { rejectWithValue }) => {
            try {
                const response = await AuthAxios.get(`/api/v1/kin_user/kinuser/`,
                    { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") } }
                )
                return response?.data
            } catch (error) {
                return rejectWithValue(error?.response?.data)
            }
        }
    )

    export const toggleUserActive = createAsyncThunk(
        'kin/kinuser_status',
        async (formData, { rejectWithValue }) => {
            try {
                const response = await AuthAxios.post(`/api/v1/kin_user/kinuser_status/`,formData,
                    { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") } }
                )
                return response?.data
            } catch (error) {
                return rejectWithValue(error?.response?.data)
            }
        }
    )

const getKinUserSlice = createSlice({
    name: 'kinUser',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(getKinUserApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getKinUserApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload
            state.message = action?.payload?.message
        })
        builder.addCase(getKinUserApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })

        builder.addCase(toggleUserActive.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(toggleUserActive.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload
            state.message = action?.payload?.message
        })
        builder.addCase(toggleUserActive.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
        
    },
})

export const getKinUserSliceReducer = getKinUserSlice.reducer