import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthAxios from '../../config/authAxios'; 


export const getEmailTempleteId = createAsyncThunk(
    'groupDealor/get-templete-id',
    async (_, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(`/api/v1/super_admin/create-copy-email-template/`,
                { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") } }
            )
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const getEmailTempleteIdSlice = createSlice({
    name: 'get-templete-id',
    initialState: { result: [], loading: false, error: null, message: "" },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getEmailTempleteId.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getEmailTempleteId.fulfilled, (state, action) => {
            state.loading = false;
            state.kinUserData = action.payload;
            state.error = null;
        });
        builder.addCase(getEmailTempleteId.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    }
    
});

export const getEmailTempleteIdReducer = getEmailTempleteIdSlice.reducer;