import { yupResolver } from "@hookform/resolvers/yup";
import EmailIcon from "@mui/icons-material/Email";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Modal,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { default as React, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { forgetPasswordViaEmailApi } from "../../store/auth/forgetPasswordViaEmail";
import ResetViaEmailOTP from "./ResetViaEmailOTP";
import { authOtpViaEmailApi } from "../../store/auth/authOtpViaEmail";
import ModalCss from "../../styles/modal.module.scss";
const schema = yup
  .object({
    email: yup.string().email("Invalid email!").required(),
  })
  .required();
export default function ForgetPasswordEmailConf({ open, setOpen }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.login);
  const [emailVerifyModal, setEmailVerifyModal] = useState(false);
  const handleClose = () => setOpen(false);
  const handleSendVierifyEmail = async (data) => {
    const newData = {
      ...data,
      user_type: loginState.result.is_admin,
      type: "email",
    };
    const passData = await dispatch(forgetPasswordViaEmailApi(newData));
    if (!passData?.payload?.error) {
      localStorage.setItem(
        "access_token",
        passData?.payload?.result?.access_token
      );
      localStorage.setItem("email", passData?.payload?.result?.email);
      dispatch(
        authOtpViaEmailApi({
          type: "email",
          user_type: loginState.result.is_admin,
        })
      );
    }
    setEmailVerifyModal(true);
    handleClose();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className={ModalCss?.customModal}
      >
        <Card className={`modal ${ModalCss?.customModal_md}`}>
          <form onSubmit={handleSubmit(handleSendVierifyEmail)}>
            <Stack spacing={2} p={3} position={"relative"}>
              <Stack
                alignItems="flex-end"
                position={"absolute"}
                right={5}
                top={5}
              >
                <IconButton onClick={handleClose}>
                  <HighlightOffIcon />
                </IconButton>
              </Stack>
              <Stack alignItems="center">
                <Typography
                  className={ModalCss?.title}
                  variant="h5"
                  fontWeight={600}
                >
                  Email Verification
                </Typography>
                <Typography className={ModalCss?.subtitle} fontSize={16}>
                  Please enter your email to verify
                </Typography>
              </Stack>
              <Box sx={{ width: "100%", marginBlock: "30px 20px !important" }}>
                <Stack
                  alignItems="center"
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"center"}
                  gap={0}
                >
                  <FormControl variant="outlined" sx={{ width: "70%" }}>
                    <OutlinedInput
                      {...register("email")}
                      error={errors?.email?.message}
                      placeholder="Email"
                      id="outlined-adornment-email"
                      startAdornment={<EmailIcon />}
                      inputProps={{ style: { paddingLeft: "8px" } }}
                      sx={{
                        fontSize: "14px",
                        borderTopRightRadius: "0px !important",
                        borderBottomRightRadius: "0px !important",
                        outline: "none !important",
                      }}
                    />
                    <FormHelperText error>
                      {errors?.email?.message}
                    </FormHelperText>
                  </FormControl>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      width: "auto",
                      alignSelf: "start",
                      padding: "12.5px 20px",
                      fontSize: "16px",
                      borderTopLeftRadius: "0px !important",
                      borderBottomLeftRadius: "0px !important",
                      outline: "none",
                    }}
                  >
                    Send
                  </Button>
                </Stack>
              </Box>
            </Stack>
          </form>
        </Card>
      </Modal>
      <ResetViaEmailOTP open={emailVerifyModal} setOpen={setEmailVerifyModal} />
    </>
  );
}
