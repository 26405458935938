import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const accessToken = useSelector((state) => state?.verifyAuthOtp?.result?.access_token);
  const checkUserToken = (accessToken) => {
    if (!accessToken || accessToken === 'undefined') {
      setIsLoggedIn(false);
      navigate('/login');
    } else {
      setIsLoggedIn(true);
    }
  };

  useEffect(() => {
    checkUserToken(accessToken);
  }, [accessToken]);

  return isLoggedIn ? children : null;
};

export default ProtectedRoute;
