import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import deleteIconPng from "../../assets/img/delete-icon.png";
import editIconPng from "../../assets/img/edit-icon.png";
export default function DataTable({
  data,
  columns,
  deleteUser,
  editUser,
  handleSelectedRows,
  type,
  selectedRowss,
}) {
  const navigate = useNavigate();

  const handleEditClick = (id) => {
    localStorage.setItem("selectedId", id);
    editUser(id);
  };

  const handleDeleteClick = (id) => {
    deleteUser(id);
  };

  const [selectedRows, setSelectedRows] = useState([]);
  useEffect(() => {
    if (selectedRowss) {
      setSelectedRows(selectedRowss);
    }
  }, [selectedRowss]);

  const handleSelectionModelChange = (newSelection) => {
    setSelectedRows(newSelection);
    handleSelectedRows(newSelection);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }

  const updatedColumns = columns.map((column) => {
    if (column.field === "snap") {
      return { ...column, filterable: false, sortable:false };
    }
    if (column.field === "user_is_active") {
      return { ...column, filterable: false, sortable:false };
    }
    return column;
  });

  const enhancedColumns = type === "order"
  ? updatedColumns // Use the updated columns
  : updatedColumns.concat({
      field: "actions",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => (
        <>
          <button
            onClick={(event) => {
              event.stopPropagation();
              handleEditClick(params.row.id);
            }}
            style={{
              padding: "7px 8px",
              borderRadius: "7px",
              fontSize: "13px",
              background: "#D7EDFE",
              border: "1px solid #B2D3ED",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "5px",
              gap: "8px",
              cursor: "pointer",
              borderColor: "rgba(143, 189, 227, 1)",
            }}
          >
            <img src={editIconPng} alt="edit" />
            Edit
          </button>
          <button
            onClick={(event) => {
              event.stopPropagation();
              handleDeleteClick(
                type === "dealer" ? params.row.email : params.row.id
              );
            }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "grey",
              border: "none",
              background: "transparent",
              cursor: "pointer",
            }}
          >
            <img src={deleteIconPng} alt="delete" />
          </button>
        </>
      ),
    });

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <DataGrid
        rows={data}
        columns={enhancedColumns.map((column) => ({
          ...column,
          disableColumnFilter: true,
          disableColumnMenu: true,
          disableColumnSort: true,
        }))}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10,50,100]}
        localeText={{
          MuiTablePagination: {
            labelRowsPerPage: "Rows Per Page",
          },
        }}
        
        onSelectionModelChange={handleSelectionModelChange}
        selectionModel={selectedRows}
        disableRowSelectionOnClick
        checkboxSelection={type === 'dealerGroup'}
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={handleSelectionModelChange}
        
      />
    </div>
  );
}
