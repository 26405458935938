import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthAxios from '../../config/authAxios'; 

export const addDealerGroupAttachment = createAsyncThunk(
    'dealerGroup/add-attachment', 
    async (formData, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post('/api/v1/super_admin/emailtemplates/', formData, {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },           
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

export const getEmailTempleteId = createAsyncThunk(
    'groupDealor/get-group-dealer',
    async (_, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(`/api/v1/super_admin/create-copy-email-template/`,
                { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") } }
            )
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const editEmailTemplate = createAsyncThunk(
    'onBoarding/edit-applicant',
    async ( payload, { rejectWithValue }) => {
        try {
            
            const response = await AuthAxios.patch(`/api/v1/super_admin/edit-copy-template/?id=${payload.editTemplateId}`,payload.formData,          
                {
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },           
                }
            )   
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const handleDeleteAttachment = createAsyncThunk(
    'onBoarding/edit-applicant',
    async ({ editTemplateId, formData }, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.patch(
                `/api/v1/super_admin/edit-copy-template/?id=${editTemplateId}`,
                formData,
                {
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },
                }
            );
            return response?.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);



const addDealerGroupSlice = createSlice({
    name: 'add-dealer-attachement',
    initialState: { result: [], loading: false, error: null, message: "" },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(addDealerGroupAttachment.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addDealerGroupAttachment.fulfilled, (state, action) => {
            state.loading = false;
            state.kinUserData = action.payload;
            state.error = null;
        });
        builder.addCase(addDealerGroupAttachment.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    }
    
});

export const addDealerGroupReducer = addDealerGroupSlice.reducer;