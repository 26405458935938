import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box, Button, Card, IconButton, Modal, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { verifyAuthOtpApi } from '../../store/auth/verifyAuthOtp';
import ModalCss from "../../styles/modal.module.scss";
export default function OTPSMSAuth({ open, setOpen }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [otp, setOtp] = useState('')
  const loginState = useSelector(state => state.login);
  const handleClose = () => setOpen(false)
  const handleVerify = () => {
    dispatch(verifyAuthOtpApi())
    navigate("/")
  }

  return (
    <>
      <Modal open={open} onClose={handleClose} className={ModalCss?.customModal}>
        <Card className={`modal ${ModalCss?.customModal_md}`}>
          <Stack spacing={2} p={3} position={"relative"}>
            <Stack alignItems="flex-end"
              position={"absolute"}
              right={5}
              top={5}><IconButton onClick={handleClose}><HighlightOffIcon /></IconButton></Stack>
            <Stack alignItems='center'>
              <Typography className={ModalCss?.title} variant='h5' fontWeight={600} >Verify Number</Typography>
              <Typography className={ModalCss?.subtitle} >We have sent code to your Registered number +91 {loginState.result.phone}</Typography>
            </Stack>
            <OtpInput
              containerStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBlock: "50px 30px" }}
              inputStyle={{ width: 72, height: 72, fontSize: "20px", border: "2px solid black", borderRadius: "10px"  }}
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span>&nbsp; &nbsp;</span>}
              renderInput={(props) => <input {...props} />}
            />
            <Box sx={{ textAlign: "center" }}>
              <Button sx={{
                  marginTop: "20px !important",
                  width: "80%",
                  padding: { lg: "20px 10px", xs: "10px" },
                  fontSize: { lg: "18px", xs: "14px" },
                  fontWeight: { lg: "500", xs: "500" },
                  borderRadius: "10px",
                }} variant='contained' onClick={handleVerify}>Verify Number</Button>
            </Box>
            <Stack justifyContent='center' direction='row' alignItems='center' fontSize={'18px'} marginTop={'30px'}>
              <Typography fontSize={'inherit'}>Don’t receive code?</Typography>
              <Button onClick={handleClose} sx={{fontSize: "18px", fontWeight: "600"}}>Resend</Button>
            </Stack>
          </Stack>
        </Card>
      </Modal>
    </>
  )
}
