import { yupResolver } from "@hookform/resolvers/yup";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PasswordChangedSuccess from "./PasswordChangedSuccess";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  IconButton,
  Modal,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { resetPasswordVerifyOtpApi } from "../../store/auth/resetPasswordVerifyOtp";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ModalCss from "../../styles/modal.module.scss";

const schema = yup
  .object({
    new_password: yup.string().required("Password is required"),
    confirm_password: yup
      .string()
      .oneOf([yup.ref("new_password"), null], "Passwords must match")
      .required("Confirm password is required"),
  })
  .required();

export default function ResetPassword({ open, setOpen }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset, // Add the reset function here
  } = useForm({ resolver: yupResolver(schema) });
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setPasswordResetSuccess(false); // Reset the password reset success state
    reset(); // Reset the form fields when the modal is closed
  };

  const handleResetPassword = (data) => {
    if (data?.new_password !== data?.confirm_password) {
      return setError("confirm_password", {
        type: "custom",
        message: "Password and confirm password do not match",
      });
    }

    dispatch(resetPasswordVerifyOtpApi(data));
    setPasswordResetSuccess(true);
    reset(); // Reset the form fields after successful password reset
    setOpen(false);
    navigate("/login");
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className={ModalCss?.customModal}
      >
        <Card className={`modal ${ModalCss?.customModal_md}`}>
          <form onSubmit={handleSubmit(handleResetPassword)}>
            <Stack spacing={2} p={3} position={"relative"}>
              <Stack
                alignItems="flex-end"
                position={"absolute"}
                right={5}
                top={5}
              >
                <IconButton onClick={handleClose}>
                  <HighlightOffIcon />
                </IconButton>
              </Stack>
              <Stack alignItems="center">
                <Typography
                  className={ModalCss?.title}
                  variant="h5"
                  fontWeight={600}
                >
                  Reset Password
                </Typography>
                <Typography className={ModalCss?.subtitle} fontSize={16}>
                  Your new password must be different from previously used
                  passwords
                </Typography>
              </Stack>

              <Box textAlign={"center"}>
                <Stack width={"90%"} margin={"20px auto 0"} spacing={2}>
                  <FormControl variant="outlined" fullWidth>
                    <OutlinedInput
                      {...register("new_password")}
                      error={!!errors?.new_password?.message}
                      placeholder="Password"
                      type={showPassword ? "text" : "password"}
                      id="outlined-adornment-password"
                      inputProps={{ style: { paddingLeft: "8px" } }}
                      startAdornment={<LockIcon />}
                      endAdornment={
                        showPassword ? (
                          <IconButton onClick={() => setShowPassword(false)}>
                            <Visibility />
                          </IconButton>
                        ) : (
                          <IconButton onClick={() => setShowPassword(true)}>
                            <VisibilityOff />
                          </IconButton>
                        )
                      }
                    />
                    <FormHelperText error>
                      {errors?.new_password?.message}
                    </FormHelperText>
                  </FormControl>

                  <FormControl variant="outlined" fullWidth>
                    <OutlinedInput
                      {...register("confirm_password")}
                      error={!!errors?.confirm_password?.message}
                      placeholder="Confirm Password"
                      type={showConfPassword ? "text" : "password"}
                      id="outlined-adornment-confirm-password"
                      inputProps={{ style: { paddingLeft: "8px" } }}
                      startAdornment={<LockIcon />}
                      endAdornment={
                        showConfPassword ? (
                          <IconButton
                            onClick={() => setShowConfPassword(false)}
                          >
                            <Visibility />
                          </IconButton>
                        ) : (
                          <IconButton onClick={() => setShowConfPassword(true)}>
                            <VisibilityOff />
                          </IconButton>
                        )
                      }
                    />
                    <FormHelperText error>
                      {errors?.confirm_password?.message}
                    </FormHelperText>
                  </FormControl>

                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      margin: "30px auto 0 !important",
                      padding: { lg: "16px 10px", xs: "10px" },
                      fontSize: { lg: "18px", xs: "14px" },
                      fontWeight: { lg: "500", xs: "500" },
                      borderRadius: "10px",
                      width: "80%",
                    }}
                  >
                    Reset password
                  </Button>
                </Stack>
              </Box>
            </Stack>
          </form>
        </Card>
      </Modal>
      <PasswordChangedSuccess open={passwordResetSuccess} setOpen={setPasswordResetSuccess} />
    </>
  );
}
