import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import InventoryIcon from '@mui/icons-material/Inventory'
import RemoveIcon from '@mui/icons-material/Remove'
import { Box, Button, Card, Grid, IconButton, Paper, Stack, TextField, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import NodataImg from "../../assets/img/noData.png"
import Appbar from '../../components/Appbar'
import Body from '../../components/Body'
import Drawer from '../../components/Drawer'
import { placeOrderApi } from '../../store/orders/placeOrder'
import { addNewQytAndPrice, deleteProduct, removeNewQytAndPrice } from '../../store/products/productCRUD'
import DeletePlacedProductConf from './DeletePlacedProductConf'
import OrderPlacedConf from './OrderPlacedConf'
import Style from "../../styles/order.module.scss"


export default function YourOrder({ first_name }) {

    const prodcutCRUDSelector = useSelector(state => state?.prodcutCRUD)
    const result = prodcutCRUDSelector

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [orderPlaced, setOrderPlaced] = useState(false)
    const [delOrderItm, setDelOrderItm] = useState(false)
    const [deleteProductIdx, setDeleteProductIdx] = useState(null)
    const [deleteProd, setDeleteProd] = useState({})
    const [qty, setQty] = useState(1)


    const columns = [
        {
            field: 'category',
            headerName: 'Category',
            minWidth: 150
        },
        {
            field: 'supplier_name',
            headerName: 'Supplier Name',
            minWidth: 200,
        },
        {
            field: 'name',
            headerName: 'name',
            minWidth: 300,
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            minWidth: 200,
            renderCell: (params) => (
                <>
                    <Stack direction='row' alignItems='center' spacing={1}>
                        <Card className='card3'>
                            <Stack direction='row' alignItems='center' spacing={2}>
                                <IconButton onClick={() => handleRemoveQuantityAndPrice(params)} className='btn2' size='small'><RemoveIcon variant='round' /></IconButton>
                                <Typography>{params?.row?.quantity}</Typography>
                                <IconButton onClick={() => handleAddQuantityAndPrice(params)} className='btn2' size='small'><AddIcon /></IconButton>
                            </Stack>
                        </Card>
                    </Stack>
                </>

            )
        },
        {
            field: 'price',
            headerName: 'Price',
            minWidth: 100
        },
        {
            field: 'delete',
            headerName: '',
            minWidth: 100,
            renderCell: (params) => (
                <IconButton onClick={() => handleDeleteProduct(params)}><DeleteIcon /></IconButton>
            )
        },
    ]

    const handlePlaceOrder = () => {
        dispatch(placeOrderApi(result))
        setOrderPlaced(true)
    }

    const handleCancelOrder = () => {
        navigate("/orders/purchase")
    }


    const handleAddQuantityAndPrice = (params) => {
        setQty(prev => prev + 1)
        const rowIdx = params.api.getRowIndexRelativeToVisibleRows(params.row.id)
        const price = result.product_name[rowIdx]['price']
        dispatch(addNewQytAndPrice({ quantity: qty, idx: rowIdx, price }))
    }


    const handleRemoveQuantityAndPrice = (params) => {
        setQty(prev => prev - 1)
        const rowIdx = params.api.getRowIndexRelativeToVisibleRows(params.row.id)
        const price = result.product_name[rowIdx]['price']
        dispatch(removeNewQytAndPrice({ quantity: qty, idx: rowIdx, price }))
    }

    const handleDeleteProduct = (params) => {
        setDelOrderItm(true)
        // setDeleteProductIdx(params.api.getRowIndexRelativeToVisibleRows(params.row.id))
        // setDeleteProd(result.product_name[deleteProductIdx])
        dispatch(deleteProduct(params?.row?.id))
    }

    const handleAddMoreProd = () => {
        navigate("/orders/purchase")
    }


    const OrderFooterComp = (props) => {
        return (
            <Grid container spacing={2} p={2}>
                <Grid item xs={6}>
                    <Stack>
                        <Typography>Please mention purpose of your order</Typography>
                        <TextField multiline />
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Stack direction='row' spacing={2}>
                                <Typography>Subtotal</Typography>
                                <Typography fontWeight={600}>10000</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} className={Style.totalsec}>
                            <Stack direction='row' spacing={2}>
                                <Typography fontWeight={600}>Total</Typography>
                                <Typography fontWeight={600}>10000</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }


    return (
        <>
            {/* <Appbar />
            <Drawer /> */}
            <Body>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Box className='divider1'></Box>
                    <Typography variant='h6'>{`Welcome ${first_name}`}</Typography>
                </Stack>
                <Stack direction='row' spacing={1} sx={{ my: 2 }} alignItems='center'>
                    <InventoryIcon fontSize='large' />
                    <Typography>Order Status</Typography>
                </Stack>
                <Stack>

                </Stack>

                <Paper elevation={0}>
                    <DataGrid
                        sx={{ maxHeight: 500 }}
                        getRowId={(row) => row?.id}
                        rows={result?.product_name}
                        columns={columns}
                        disableRowSelectionOnClick
                        slots={{
                            noRowsOverlay: () => <Box className='noData'><img src={NodataImg} alt='no data found image' /></Box>,
                            footer: OrderFooterComp,
                        }}
                        pagination
                        rowsPerPageOptions={[25, 50, 100]}
                    />
                </Paper>
                <Stack sx={{ mt: 2 }} direction='row' justifyContent='space-between'>
                    <Button variant='outlined' onClick={handleAddMoreProd}>ADD More Product</Button>
                    <Stack direction='row' spacing={1}>
                        <Button onClick={handleCancelOrder} >Cancel</Button>
                        <Button variant='contained' onClick={handlePlaceOrder}>Place Order</Button>
                    </Stack>
                </Stack>
            </Body>

            <OrderPlacedConf open={orderPlaced} setOpen={setOrderPlaced} result={result} />
            <DeletePlacedProductConf open={delOrderItm} setOpen={setDelOrderItm} result={result} produtIdx={deleteProductIdx} deleteProd={deleteProd} />
        </>
    )
}
