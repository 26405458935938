import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import Switch from '@mui/material/Switch';
import { RiDeleteBinLine, RiEdit2Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { sendInvite } from "../../store/dealerGroup/sendInvite";
import { showToast } from "../../toast/toast";

export default function SavedInviteDataTable({
    data,
    columns,
    handleSelectedRows,
    editUser,
  
}) {
    const getInvite = useSelector((state) => state?.getInviteSliceReducer?.kinUserData?.data);
    const dispatch = useDispatch();
    const [selectedRows, setSelectedRows] = useState();
    const [invites,setInvites] = useState([]);
    const [rows,setRows] = useState([]);

    useEffect(() => {
        if(data?.length>0){
            setInvites(data)
        }
        
    },[data])
    const navigate = useNavigate();
    const handleEditClick = (id) => {
     navigate("/users/dealer", { state: { id: id } });
    };

    const handleSendInvite = (id) => {
        const payload = { "invites_model_id": id }
        dispatch(sendInvite(payload))
          .then((data) => {
            showToast(data.payload.message, 1);
          })
          .catch((error) => {
            console.error("Error editing email template:", error);
          });
      }



    const handleSelectionModelChange = (newSelection) => {
 
        setSelectedRows(newSelection);
        handleSelectedRows(newSelection);
    }; 

    return (
        <div style={{
            height: 400,
            width: "100%",
            background: "#fff",
            marginBlock: "20px",
            boxShadow: "0px 4px 4px 0px #DCEAF7",
        }}>
            <DataGrid
                getRowId={(row) => row?.id}
                rows={invites}
                columns={columns.concat({
                    field: "action",
                    headerName: "Action",
                    sortable: false,
                    width: 150,
                    renderCell: (params) => (
                        <>
                            <button
                                onClick={() => {
                                    handleEditClick(params.id);
                                }}
                                style={{
                                    padding: "5px 5px",
                                    borderRadius: '5px',
                                    fontSize: '13px',
                                    background: "#D7EDFE",
                                    border: "1px solid #B2D3ED",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginRight: "5px",
                                    cursor: "pointer"
                                }}>
                                Review
                            </button>
                            <button
                                style={{
                                    padding: "5px 5px",
                                    borderRadius: '5px',
                                    fontSize: '13px',
                                    background: "#D7EDFE",
                                    border: "1px solid #B2D3ED",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginRight: "5px",
                                    cursor: "pointer"
                                }}
                                onClick={()=>handleSendInvite(params.id)}
                                >
                                Send Invite
                            </button>
                        </>
                    ),
                })}
                // checkboxSelection
                // rowSelectionModel={selectedRows}
                onRowSelectionModelChange={handleSelectionModelChange}
                disableSelectionOnClick
            />
        </div>
    );
}