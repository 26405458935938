export const businessBackground = ['4W', '2W', 'CV', 'Others'];
export const brand = [
    'Hyundai',
    'Mahindra',
    'Tata',
    'Honda',
    'Toyota',
    'Renault',
    'Nissan',
    'VW',
    'Skoda',
    'Ford',
    'MG',
    'Citreon',
    'Jeep',
    'BMW',
    'Audi',
    'Mercedes',
    'JLR',
    'RR',
    'Porsche',
    'BYD',
    'Volvo',
    'Tata CV',
    'HMSI',
    'Hero',
    'Suzuki',
    'TVS',
    'Bajaj',
    'Others',
    'Others2'
];
