import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, FormControl, Grid, Stack, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { inputDrawingApi } from '../../store/home/layoutTable';
import Body from '../../components/Body';
import { useNavigate } from 'react-router-dom';
import FilterStyles from "../../styles/filters.module.scss";
import meter from "../../assets/img/meter.png";
import { convertToDateFormatYearWithTwoDigits } from '../../utils/dateFormater';
import { resetInputDrawingByClusterState } from '../../store/home/getInputDrawing';

export default function LayoutTable() {
    localStorage.removeItem("selectedOutletIdToView");
    const [filters, setFilters] = useState([]);
    const [selectedButton, setSelectedButton] = useState("recived_from_dealor");
    const [location, setLocation] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const inputDrawing = useSelector((state) => state?.inputDrawingReducer?.result?.results?.data);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const handleNavigate = (outletId) => {
        localStorage.setItem("selectedOutletIdToView", outletId);
        navigate(`/layout/view`);
    };

    const handleChange = (event) => {
        setLocation(event.target.value);
    };

    useEffect(() => {
        dispatch(inputDrawingApi());
        dispatch(resetInputDrawingByClusterState())
    }, [dispatch]);



    useEffect(() => {
        if (inputDrawing && selectedButton) {
            const filtered = inputDrawing.filter(feedback => {
                switch (selectedButton) {
                    case 'recived_from_dealor':
                        return feedback.recived_from_dealor;
                    case 'send_to_dealor':
                        return feedback.send_to_dealor;
                    case 'recived_from_inn':
                        return feedback.recived_from_inn;
                    case 'send_to_inn':
                        return feedback.send_to_inn;
                    default:
                        return true;
                }
            });
            setFilters(filtered);
        }
    }, [inputDrawing, selectedButton]);

    const filterFeedback = (type) => {
        setSelectedButton(type);
    };

    return (
        <Body>
            <Stack
                direction="row"
                spacing={10}
                sx={{ my: 2 }}
                justifyContent="space-between"
                alignItems={"center"}
            >
                <Stack direction="row" alignItems="center">
                    <img src={meter} alt="Meter" />

                    <Typography
                        color={"#05141F"}
                        fontSize={"17px"}
                        fontWeight={400}
                        marginInlineStart={"10px"}
                        fontFamily={"'KiaSignatureRegular' !important"}
                    >
                        Layout
                    </Typography>
                </Stack>
                <Stack direction="row" flex={1} justifyContent="end" gap={2}>
                    <Box flexGrow={1} maxWidth={180}>
                        <Stack direction="row">
                            <Typography className={FilterStyles.filtersLabel} variant="label">
                                By Dealer
                            </Typography>
                        </Stack>
                        <TextField className="filterField2"
                            id="standard-basic"
                            placeholder="Dealer"
                            variant="standard"
                            inputProps={{ style: { fontSize: 13 } }}
                        />
                    </Box>
                    <Box flexGrow={1} maxWidth={180}>
                        <Stack direction="row">
                            <Typography className={FilterStyles.filtersLabel} variant="label">By Location</Typography>
                        </Stack>
                        <FormControl variant="standard" sx={{ width: "100%" }}>
                            <TextField className="filterField2"
                                id="standard-basic"
                                placeholder="Location"
                                variant="standard"
                                inputProps={{ style: { fontSize: 13 } }}
                            />
                        </FormControl>
                    </Box>
                </Stack>
            </Stack>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginBlock: "30px 30px !important" }}>
                        <Box className="d-flex flex-wrap" sx={{ gap: "9px" }}>
                            <Button
                                className={selectedButton === 'recived_from_dealor' ? 'layout-blue-btn' : 'layout-lightblue-btn'}
                                onClick={() => filterFeedback('recived_from_dealor')}
                            >
                                Received from dealer
                            </Button>
                            <Button
                                className={selectedButton === 'send_to_dealor' ? 'layout-blue-btn' : 'layout-lightblue-btn'}
                                onClick={() => filterFeedback('send_to_dealor')}
                            >
                                Send to Dealer
                            </Button>
                            <Button
                                className={selectedButton === 'recived_from_inn' ? 'layout-blue-btn' : 'layout-lightblue-btn'}
                                onClick={() => filterFeedback('recived_from_inn')}
                            >
                                Received from IIN
                            </Button>
                            <Button
                                className={selectedButton === 'send_to_inn' ? 'layout-blue-btn' : 'layout-lightblue-btn'}
                                onClick={() => filterFeedback('send_to_inn')}
                            >
                                Send to IIN
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} className="d-flex">
                        <TableContainer component={Paper} sx={{ borderRadius: "10px 10px 0 0" }}>
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Input Drawing</StyledTableCell>
                                        <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Input Drawing Date</StyledTableCell>
                                        <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Schematic Layout Status</StyledTableCell>
                                        <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Schematic Date</StyledTableCell>
                                        <StyledTableCell sx={{ background: "#85949F !important" }} align="center">GFC</StyledTableCell>
                                        <StyledTableCell sx={{ background: "#85949F !important" }} align="center">GFC Date</StyledTableCell>
                                        <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Payment</StyledTableCell>
                                        <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Payment Date</StyledTableCell>
                                        <StyledTableCell sx={{ background: "#85949F !important" }} align="center">Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filters?.map((row) =>
                                        <StyledTableRow key={row.dealor_outlet_id}>
                                            <StyledTableCell component="th" scope="row" align="center">{row.dealor_outlet_name}</StyledTableCell>
                                            <StyledTableCell align="center">{row.created_at ? convertToDateFormatYearWithTwoDigits(row.created_at) : "-"}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                {row?.schematic_status === false ? (
                                                    <Button className="notdoneBtn">Not Done</Button>
                                                ) : (
                                                    <Button className="doneBtn">Done</Button>
                                                )}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{row.schematic_date ? convertToDateFormatYearWithTwoDigits(row.schematic_date) : "-"}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                {row.gfc_status === true ? (
                                                    <Button className="doneBtn">Done</Button>
                                                ) : (
                                                    <Button className="notdoneBtn">Not Done</Button>
                                                )}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{row.gfc_date ? row.gfc_date : "-"}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                {row.payment_status === true ? (
                                                    <Button className="doneBtn">Paid</Button>
                                                ) : (
                                                    <Button className="notdoneBtn">Not Paid</Button>
                                                )}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{row.gfc_status === true ? row.payment_date : "-"}</StyledTableCell>

                                            <StyledTableCell align="center">
                                                <Button className="actionBtn"
                                                    variant='contained'
                                                    onClick={() => handleNavigate(row.dealor_outlet_id)}
                                                >View</Button>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>
        </Body>
    );
}