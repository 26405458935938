import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"

    export const getGroupDealerApi = createAsyncThunk(
        'groupDealor/get-group-dealer',
        async (_, { rejectWithValue }) => {
            try {
                const response = await AuthAxios.get(`/api/v1/dealor/dealer-group`,
                    { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") } }
                )
                return response?.data
            } catch (error) {
                return rejectWithValue(error?.response?.data)
            }
        }
    )

    export const groupToggleActive = createAsyncThunk(
        'groupDealor/toggle-user_status',
        async (formData, { rejectWithValue }) => {
            try {
                const response = await AuthAxios.post(`/api/v1/kin_user/kinuser_status/`,formData,
                    { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") } }
                )
                return response?.data
            } catch (error) {
                return rejectWithValue(error?.response?.data)
            }
        }
    )

    

const getGroupDealorSlice = createSlice({
    name: 'groupDealer',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(getGroupDealerApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getGroupDealerApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload
            state.message = action?.payload?.message
        })
        builder.addCase(getGroupDealerApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })

        builder.addCase(groupToggleActive.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(groupToggleActive.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload
            state.message = action?.payload?.message
        })
        builder.addCase(groupToggleActive.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })        
    },
})

export const getGroupDealorSliceReducer = getGroupDealorSlice.reducer