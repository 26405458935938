import React, { useState } from "react";

import { DataGrid, GridRowSelectionModel } from "@mui/x-data-grid";

import { useSelector } from "react-redux";
import { RiDeleteBinLine } from "react-icons/ri";
import { RiEdit2Line } from "react-icons/ri";

const columns = [
  { field: "firstName", headerName: "First name", flex: 1 },
  { field: "lastName", headerName: "Last name", flex: 1 },
  { field: "Email", headerName: "Email", flex: 1 },
  { field: "phone", headerName: "Phone", flex: 1 },
  { field: "location", headerName: "Location", flex: 1 },
  { field: "pincode", headerName: "Pincode", flex: 1 },
  { field: "cluster", headerName: "Cluster", flex: 1 },
  { field: "region", headerName: "Region", flex: 1, flexShrink: 0 },
];

export default function OrderDataTable(props) {
  const rows =
    props.applicantData?.map((applicant, index) => ({
      id: index + 1,
      firstName: applicant.first_name,
      lastName: applicant.last_name,
      Email: applicant.email,
      phone: applicant.phone,
      location: applicant.location,
      pincode: applicant.pincode,
      cluster: applicant.cluster,
      region: applicant.zone,
    })) || [];
  const handleEditClick = (id) => {
  };

  const handleDeleteClick = (id) => {
  };

  const handleSelectionModelChange = (selectionModel) => {
    setSelectedRows(selectionModel);
  };

  const [selectedRows, setSelectedRows] = React.useState(() =>
    rows.map((r) => r.id)
  );

  return (
    <div
      style={{
        height: 400,
        background: "#fff",
        marginBlock: "20px",
        boxShadow: "0px 4px 4px 0px #DCEAF7",
      }}
    >
      <DataGrid
        rows={rows}
        //columns={columns}
        columns={columns.concat({
          field: "actions",
          headerName: "Actions",
          renderCell: (params) => (
            <>
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  handleEditClick(params.row.id);
                }}
                // onClick={() => handleEditClick(params.row.id)}
                style={{
                  padding: "8px 12px",
                  borderRadius: "7px",
                  fontSize: "13px",
                  background: "#D7EDFE",
                  border: "1px solid #B2D3ED",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "5px",
                  gap: "8px",
                  cursor: "pointer",
                  borderColor: "rgba(143, 189, 227, 1)",
                }}
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.677621 6.89088L0.562012 10.1361L4.16494 10.032L11.6284 3.30952L8.13665 0.172363L0.677621 6.89088ZM3.91469 9.51133L1.17079 9.59174L1.26006 7.11628L6.08933 2.76645L8.74689 5.15754L3.91469 9.51133ZM9.16104 4.78583L6.50201 2.38946L8.13665 0.917108L10.7957 3.31215L9.16104 4.78583Z"
                    fill="#05141F"
                  />
                  <path d="M12 11.3003H0V11.8275H12V11.3003Z" fill="#05141F" />
                </svg>
                Edit
              </button>
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  handleDeleteClick(params.row.email);
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "grey",
                  border: "none",
                  background: "transparent",
                  cursor: "pointer",
                }}
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.5">
                    <path
                      d="M14.4231 2.30769H10.9615V1.73077C10.9615 1.27174 10.7792 0.831513 10.4546 0.506931C10.13 0.182348 9.6898 0 9.23077 0H5.76923C5.3102 0 4.86997 0.182348 4.54539 0.506931C4.22081 0.831513 4.03846 1.27174 4.03846 1.73077V2.30769H0.576923C0.423914 2.30769 0.277171 2.36848 0.168977 2.47667C0.0607828 2.58486 0 2.73161 0 2.88462C0 3.03762 0.0607828 3.18437 0.168977 3.29256C0.277171 3.40076 0.423914 3.46154 0.576923 3.46154H1.73077V12.1154C1.73169 12.8802 2.03589 13.6133 2.57667 14.1541C3.11744 14.6949 3.85062 14.9991 4.61538 15H10.3846C11.1494 14.9991 11.8826 14.6949 12.4233 14.1541C12.9641 13.6133 13.2683 12.8802 13.2692 12.1154V3.46154H14.4231C14.5761 3.46154 14.7228 3.40076 14.831 3.29256C14.9392 3.18437 15 3.03762 15 2.88462C15 2.73161 14.9392 2.58486 14.831 2.47667C14.7228 2.36848 14.5761 2.30769 14.4231 2.30769ZM5.19231 1.73077C5.19231 1.57776 5.25309 1.43102 5.36128 1.32282C5.46948 1.21463 5.61622 1.15385 5.76923 1.15385H9.23077C9.38378 1.15385 9.53052 1.21463 9.63872 1.32282C9.74691 1.43102 9.80769 1.57776 9.80769 1.73077V2.30769H5.19231V1.73077ZM12.1154 12.1154C12.1154 12.5744 11.933 13.0146 11.6085 13.3392C11.2839 13.6638 10.8436 13.8462 10.3846 13.8462H4.61538C4.15636 13.8462 3.71613 13.6638 3.39155 13.3392C3.06696 13.0146 2.88462 12.5744 2.88462 12.1154V3.46154H12.1154V12.1154Z"
                      fill="#536476"
                    />
                    <path
                      d="M5.19216 5.19238C5.03915 5.19238 4.89241 5.25317 4.78421 5.36136C4.67602 5.46955 4.61523 5.6163 4.61523 5.76931V10.9616C4.61523 11.1146 4.67602 11.2614 4.78421 11.3696C4.89241 11.4778 5.03915 11.5385 5.19216 11.5385C5.34517 11.5385 5.49191 11.4778 5.6001 11.3696C5.7083 11.2614 5.76908 11.1146 5.76908 10.9616V5.76931C5.76908 5.6163 5.7083 5.46955 5.6001 5.36136C5.49191 5.25317 5.34517 5.19238 5.19216 5.19238Z"
                      fill="#536476"
                    />
                    <path
                      d="M9.80788 5.19238C9.65487 5.19238 9.50813 5.25317 9.39993 5.36136C9.29174 5.46955 9.23096 5.6163 9.23096 5.76931V10.9616C9.23096 11.1146 9.29174 11.2614 9.39993 11.3696C9.50813 11.4778 9.65487 11.5385 9.80788 11.5385C9.96089 11.5385 10.1076 11.4778 10.2158 11.3696C10.324 11.2614 10.3848 11.1146 10.3848 10.9616V5.76931C10.3848 5.6163 10.324 5.46955 10.2158 5.36136C10.1076 5.25317 9.96089 5.19238 9.80788 5.19238Z"
                      fill="#536476"
                    />
                  </g>
                </svg>
              </button>
            </>

            // <>
            //   <button onClick={() => handleEditClick(params.row.id)}>Edit</button>
            //   <button onClick={() => handleDeleteClick(params.row.id)}>Delete</button>
            // </>
          ),
        })}
        style={{
          "& .MuiDataGrid-oddRows": {
            backgroundColor: "rgba(201, 226, 251, 0.6)",
          },
          "& .MuiDataGrid-evenRows": {
            backgroundColor: "rgba(201, 226, 251, 0.6)",
          },
        }}
        checkboxSelection
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={setSelectedRows}
        disableRowSelectionOnClick
      />
    </div>
  );
}
