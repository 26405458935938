import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthAxios from '../../config/authAxios'; 


export const getInviteApi = createAsyncThunk(
    'dealerGroup/getInvite',
    async (_, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(`/api/v1/super_admin/invites`,
                { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") } }
            )
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

const getInviteSlice = createSlice({
    name: 'getInvite',
    initialState: { result: [], loading: false, error: null, message: "" },
    extraReducers: (builder) => {
        builder.addCase(getInviteApi.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getInviteApi.fulfilled, (state, action) => {
            state.loading = false;
            state.kinUserData = action.payload;
            state.error = null;
        });
        builder.addCase(getInviteApi.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    }
    
});

export const getInviteSliceReducer = getInviteSlice.reducer;