import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthAxios from '../../config/authAxios'; 


export const addDealerGroupOutletAPI = createAsyncThunk(
    'dealerGroup/add-dealer-group-outlet',
    async ( formData, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post(`/api/v1/dealor/outlet`,formData,          
                {
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },           
                }
            )   
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)

export const editDealerGroupOutletAPI = createAsyncThunk(
    'dealerGroup/edit-dealer-group-outlet',
    async ( formData, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.patch(`/api/v1/dealor/outlet?id=${formData?.id}`,formData,          
                {
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },           
                }
            )   
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)


export const deleteDealerGroupOutletAPI = createAsyncThunk(
    'dealerGroup/delete-dealeroutlet',
    async (id, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.delete(`/api/v1/dealor/outlet?id=${id}`,
                {
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },
                }
            )
            return response?.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data);
        }
    }
);


const addDealerGroupOutletSlice = createSlice({
    name: 'add-dealer-outlet',
    initialState: { result: [], loading: false, error: null, message: "" },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(addDealerGroupOutletAPI.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addDealerGroupOutletAPI.fulfilled, (state, action) => {
            state.loading = false;
            state.kinUserData = action.payload;
            state.error = null;
        });
        builder.addCase(addDealerGroupOutletAPI.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        

        builder.addCase(editDealerGroupOutletAPI.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(editDealerGroupOutletAPI.fulfilled, (state, action) => {
            state.loading = false;
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        });
        builder.addCase(editDealerGroupOutletAPI.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
            
        });


        builder.addCase(deleteDealerGroupOutletAPI.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(deleteDealerGroupOutletAPI.fulfilled, (state, action) => {
            state.loading = false;
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        });
        builder.addCase(deleteDealerGroupOutletAPI.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
            
        });

    }
});

export const addDealerGroupOutletReducer = addDealerGroupOutletSlice.reducer;