import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"
import { useId } from "react"

    export const getSupplierUserApi = createAsyncThunk(
        'supplier/get-supplierUser',
        async (_, { rejectWithValue }) => {
            try {
                const response = await AuthAxios.get(`/api/v1/supplier/supplieruser/`,
                    { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") } }
                )
                return response?.data
            } catch (error) {
                return rejectWithValue(error?.response?.data)
            }
        }
    )


    export const supplierUserActive = createAsyncThunk(
        'supplier/supplierUser_status',
        async (formData, { rejectWithValue }) => {
            try {
                const response = await AuthAxios.post(`/api/v1/supplier/supplier_user_status/`,formData,
                    { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") } }
                )
                return response?.data
            } catch (error) {
                return rejectWithValue(error?.response?.data)
            }
        }
    )


const getSupplierUserSlice = createSlice({
    name: 'SupplierUser',
    initialState: { result: [], loading: false, error: null, message: "" },
   
    extraReducers: (builder) => {
        builder.addCase(getSupplierUserApi.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getSupplierUserApi.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload
            state.message = action?.payload?.message
        })
        builder.addCase(getSupplierUserApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })

        builder.addCase(supplierUserActive.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(supplierUserActive.fulfilled, (state, action) => {
            state.loading = false
            state.result = action?.payload
            state.message = action?.payload?.message
        })
        builder.addCase(supplierUserActive.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
        })
        
    },
})

export const getSupplierUserSliceReducer = getSupplierUserSlice.reducer