import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthAxios from '../../config/authAxios'; 

export const addKinUserApi = createAsyncThunk(
    'kin/add-kinuser', 
    async (formData, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.post('/api/v1/kin_user/kinuser/', formData, {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },           
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

export const editKinUserApi = createAsyncThunk(
    'kin/edit-kinUser',
    async ( formData, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.patch(`/api/v1/kin_user/kinuser/`,formData,          
                {
                    headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },           
                }
            )   
            return response?.data
        } catch (error) {
            return rejectWithValue(error?.response?.data)
        }
    }
)


export const deleteKinUserApi = createAsyncThunk(
    'onBoarding/delete-kin-user',
    async (idToDelete, { rejectWithValue }) => {
      try {
        const response = await AuthAxios.delete(`/api/v1/kin_user/kinuser/`, {
          headers: { 'Authorization': 'Bearer ' + localStorage.getItem("access_token") },
          data: { user_id: idToDelete },
        });
        return response?.data;
      } catch (error) {
        return rejectWithValue(error?.response?.data);
      }
    }
  );

const addKinUserSlice = createSlice({
    name: 'add-kinuser',
    initialState: { result: [], loading: false, error: null, message: "" },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(addKinUserApi.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addKinUserApi.fulfilled, (state, action) => {
            state.loading = false;
            state.kinUserData = action.payload;
            state.error = null;
        });
        builder.addCase(addKinUserApi.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        

        builder.addCase(editKinUserApi.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(editKinUserApi.fulfilled, (state, action) => {
            state.loading = false;
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        });
        builder.addCase(editKinUserApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
            
        });


        builder.addCase(deleteKinUserApi.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(deleteKinUserApi.fulfilled, (state, action) => {
            state.loading = false;
            state.result = action?.payload?.result
            state.message = action?.payload?.message
        });
        builder.addCase(deleteKinUserApi.rejected, (state, action) => {
            state.loading = false
            state.error = action?.payload?.error
            state.message = action?.payload?.message
            
        });

    }
});

export const addKinUserReducer = addKinUserSlice.reducer;
