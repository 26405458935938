import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    Modal,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useRef, useState } from "react";
  import HighlightOffIcon from "@mui/icons-material/HighlightOff";
  import ModalCss from "../../styles/modal.module.scss";
  import { makeStyles } from "@mui/styles";
  import { useDispatch, useSelector } from "react-redux";
  import { showToast } from "../../toast/toast";
  import { addRemarkSchema } from "../validationSchemas";
import { ordersApi, remarkOrderApi } from "../../store/orders/orders.js";
  
  const useStyles = makeStyles((theme) => ({
    bgLightGray: {
      backgroundColor: "rgba(213, 222, 229, 1)",
    },
    borderRadius10: {
      borderRadius: "10px",
    },
    p10: {
      padding: "10px",
    },
    p20: {
      padding: "20px",
    },
    buttonsStyle: {
      backgroundColor: "#D7EDFE",
    },
    fullWidth: {
      width: "100%",
    },
    marginBottom20: {
      marginBottom: "20px",
    },
    inputGroup: {
      display: "flex",
      gap: "20px",
      marginBottom: "20px",
    },
    imageUpload: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      border: "2px dashed #ccc",
      padding: "20px",
      borderRadius: "10px",
      cursor: "pointer",
      position: "relative",
    },
    imageUploadText: {
      marginTop: "10px",
      color: "#888",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    previewImage: {
      maxWidth: "100%",
      maxHeight: "200px",
      borderRadius: "10px",
    },
    deleteIcon: {
      position: "absolute",
      top: "10px",
      right: "10px",
      backgroundColor: "#fff",
      borderRadius: "50%",
      cursor: "pointer",
    },
  }));
  
  const ItemRemark = ({ open, handleClose, subOrderId,mainOrderId, setAllNoteEmpty }) => {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const classes = useStyles();
    const inputRef = useRef(null);
    const [inputValues, setInputValues] = useState();
    const [loading, setLoading] = useState(false);
    
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setInputValues({ ...inputValues, [name]: value });
    };
  
    
    const resetForm = () => {
      setInputValues({
        remark: "",
      })
    };
  
    const submitRemarkDetails = (e) => {
      e.preventDefault();
       addRemarkSchema.validate(inputValues, { abortEarly: false })
          .then(() => {
              addRequestAPI();
              setErrors({});
          })
          .catch((validationErrors) => {
              const newErrors = {};
              validationErrors.inner.forEach((error) => {
                  newErrors[error.path] = error.message;
              });
              setErrors(newErrors);
          });
    };
  
    const addRequestAPI = async () => {
      setLoading(true);
      try {
          const dataToBeSent = new FormData();
          dataToBeSent.append('main_order_id', mainOrderId);
          dataToBeSent.append('sub_order_id', subOrderId);
          dataToBeSent.append('remark', inputValues?.remark);
          const response = await dispatch(remarkOrderApi(dataToBeSent));
          if(response?.payload?.status === 'success'){
              showToast(response?.payload?.message, 1)
              setAllNoteEmpty()
              dispatch(ordersApi())
              resetForm();
              handleClose();
              setLoading(false)
              return

          }
          setLoading(false)
          showToast('Failed to add remark', 2)

          // showToast('Remark added successfully', 1)
          // dispatch(ordersApi())
          // resetForm();
          // handleClose();
      } catch (error) {
          showToast(error, 2);
      }
      
    }
  
  
    const handleCancel = () => {
      resetForm();
      handleClose();
    };
    
    return (
      <Modal
        open={open}
        onClose={() => {}}
        className={ModalCss?.customModal}
        BackdropProps={{
          style: { pointerEvents: "none" }, // Disable click events on the backdrop
        }}
        
      >
        <Card className={`modal ${ModalCss?.customModal_md}`}>
          <CardHeader
            className="popup-header-rev"
            action={
              <IconButton onClick={handleCancel}>
                <HighlightOffIcon />
              </IconButton>
            }
            title={
              <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                <Typography variant="h6">Add Remark</Typography>
              </Box>
            }
          />
          <Divider />
          <CardContent sx={{ overflowY: 'auto', maxHeight: '60vh' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth margin="normal">
                  <FormLabel htmlFor="kia-specification">Enter Remark</FormLabel>
                  <TextField
                    id="remark"
                    value={inputValues?.remark}
                    onChange={handleInputChange}
                    name="remark"
                    error={Boolean(errors.remark)}
                    helperText={errors.remark}
                    sx={{ marginTop: "10px" }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
  
  
          <CardActions disableSpacing>
            <Box component={"div"} width={"100%"} marginBlockStart={0.5} className={classes.buttonContainer}>
              <Button
                variant="contained"
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: 1.8,
                  boxShadow: "none",
                  backgroundColor: "#000 !important",
                  p: {
                    lg: "15px 46px",
                    md: "13px 35px",
                    sm: "10px 30px",
                    xs: "10px 30px",
                  },
                }}
                className={classes.buttonsStyle}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: 1.8,
                  boxShadow: "none",
                  backgroundColor: "#000 !important",
                  p: {
                    lg: "15px 46px",
                    md: "13px 35px",
                    sm: "10px 30px",
                    xs: "10px 30px",
                  },
                }}
                className={classes.buttonsStyle}
                onClick={submitRemarkDetails}
              >
                {loading ? <CircularProgress size={40} sx={{ color: "#ffffff" }} /> : "Submit"}
              </Button>
            </Box>
          </CardActions>
          </Card>
        </Modal>
      );
    };
  
    export default ItemRemark;
  