import * as yup from "yup";


export const loginSchema = yup.object({
  email: yup.string().email("Invalid email address").required("Email is Required"),
  password: yup.string().matches().required("Password is Required"),
  
})
.required();


export const addApplicantSchema = yup.object({
  first_name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters').required("Please provide First Name"),
  last_name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters').required("Please provide Last Name"),
  email: yup.string().matches( /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,'Invalid email address').required("Please provide Email"),
  phone: yup.string().matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits").required("Please provide Phone Number"),
  address: yup.string().required("Please provide Address"),
  city: yup.string().required("Please provide City"),
  pincode: yup.string().required("Please provide Pincode"),
  zone: yup.string().required("Please provide Zone"),
  state: yup.string().required("Please provide State"),
})
.required();

export const addKinUserSchema = yup.object({
  first_name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters').required("Please provide First Name"),
  last_name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters').required("Please provide Last Name"),
  email: yup.string().matches( /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,'Invalid email address').required("Please provide Email"),
  password: yup.string().test(
    'password-required',
    'Please provide a Password',
    function (value) {
      const { selectedId } = this.options.context;
      return selectedId || (!!value && value.trim() !== '');
    }
  ).min(8, "Password must be at least 8 characters long"),
  phone: yup.string().matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits").required("Please provide Phone Number"),
  address: yup.string().required("Please provide Address"),
  city_id: yup.string().required("Please provide City"),
  pin_code: yup.string().matches(/^[0-9]{6}$/, "Pincode must be exactly 6 digits").required("Please provide Pincode"),
  zone_id: yup.string().required("Please provide Zone"),
  state_id: yup.string().required("Please provide State"),
  department_id: yup.string().required("Please provide Department"),
}).required();


export const addInnoceansUserSchema = yup.object({
  first_name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters').required("Please provide First Name"),
  last_name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters').required("Please provide Last Name"),
  email: yup.string().matches( /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,'Invalid email address').required("Please provide Email"),
  password: yup.string().test(
    'password-required',
    'Please provide a Password',
    function (value) {
      const { selectedId } = this.options.context;
      return selectedId || (!!value && value.trim() !== '');
    }
  ).min(8, "Password must be at least 8 characters long"),
  phone: yup.string().matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits").required("Please provide Phone Number"),
  address: yup.string().required("Please provide Address"),
  city_id: yup.string().required("Please provide City"),
  pin_code: yup.string().matches(/^[0-9]{6}$/, "Pincode must be exactly 6 digits").required("Please provide Pincode"),
  zone_id: yup.string().required("Please provide Zone"),
  state_id: yup.string().required("Please provide State"),
}).required();

// export const addInnoceansUserSchema = yup.object({
//   first_name: yup.string().required("Please provide First Name"),
//   last_name: yup.string().required("Please provide Last Name"),
//   email: yup.string().email("Invalid email address").required("Please provide Email"),
//   password: yup.string().required("Please provide a Password").min(8, "Password must be at least 8 characters long"),
//   phone: yup.string().matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits").required("Please provide Phone Number"),
//   address: yup.string().required("Please provide Address"),
//   city_id: yup.string().required("Please provide City"),
//   pincode: yup.string().matches(/^[0-9]{6}$/, "Pincode must be exactly 6 digits").required("Please provide Pincode"),
//   zone_id: yup.string().required("Please provide Zone"),
//   state_id: yup.string().required("Please provide State"),
//   //role: yup.string().required("Please provide Role"), 
// }).required();

export const addGroupSchema = yup.object({
  dealer_group_name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters').required("Please provide Dealer Group Name"),
  salutation: yup.string().required("Please provide Salutation"),
  dob: yup.string().required("Please provide Date of Birth"),
  dp_name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters').required("Please provide DP Name"),
  dealer_priciple_email_address: yup.string().matches( /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,'Invalid email address').required("Please provide Email"),
  dealer_principle_mobile_number: yup.string().matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits").required("Please provide Phone Number"),
  correspondence_address: yup.string().required("Please provide Address"),
  pin_code: yup.string().matches(/^[0-9]{6}$/, "Pincode must be exactly 6 digits").required("Please provide Pincode"),
  partner_type: yup.string().required("Please provide Partner"),
  main_city: yup.string().required("Please provide Main City"),
  business_background: yup.array()
      .of(yup.string()) // Define the type of array elements
      .min(1, "Please select at least one option for Business Background")
      .required("Please select at least one option for Business Background"),
  brands: yup.array()
      .of(yup.string()) // Define the type of array elements
      .min(1, "Please select at least one option for Brand")
      .required("Please select at least one option for Brand"),  
}).required(); 



export const addGroupOutletSchema = yup.object({
  dealer_group_id: yup.string().required("Please provide Dealer Group"),
  trade_name: yup.string().required("Please provide Trade Name"),
  region_id: yup.string().required("Please provide Region"),
  state_id: yup.string().required("Please provide State"),
  city_id: yup.string().required("Please provide City"),
  cluster_id: yup.string().required("Please provide Cluster"),
  location: yup.string().required("Please provide Location"),
  tier_id: yup.string().required("Please provide Tier"),
  outlet_type_id: yup.string().required("Please provide Outlet Type"),
  facility_type_id: yup.string().required("Please provide Facility Type"),
  project_type_showroom: yup.string().required("Please provide Project Type (Showroom)"),
  project_type_workshop: yup.string().required("Please provide Project Type (Workshop)"),
  ownership_showroom: yup.string().required("Please provide Ownership (Showroom)"),
  ownership_workshop: yup.string().required("Please provide Ownership (Workshop)"),
  // lease_expiry_date_showroom : yup.string().required("Please provide Lease Expiry Date (Showroom)"),
  // lease_expiry_date_workshop : yup.string().required("Please provide Lease Expiry Date (Workshop)"),
  showroom_address: yup.string().required("Please provide Showroom Address"),
  showroom_pincode: yup.string().required("Please provide Showroom Pincode"),
  showroom_longitude: yup.string().required("Please provide Showroom Longitude"),
  showroom_latitude: yup.string().required("Please provide Showroom Latitute"),
  workshop_address: yup.string().required("Please provide Workshop Address"),
  workshop_pincode: yup.string().required("Please provide Workshop Pincode"),
  workshop_longitude: yup.string().required("Please provide Workshop Longitude"),
  workshop_latitude: yup.string().required("Please provide Workshop Latitude"),
  status: yup.string().required("Please provide Status"),
  appointment_date : yup.string().required("Please provide Appointment Date"),
  start_of_operation_target_date_showroom : yup.string().required("Please provide Date of Start of Operation Showroom"),
  start_of_operation_target_date_workshop : yup.string().required("Please provide Date of Start of Operation Workshop"),
  // closure_date_showroom : yup.string().required("Please provide Closure Date showroom"),
  // closure_date_workshop : yup.string().required("Please provide Closure Date workshop"),
  facility_category: yup.array().of(yup.string().required()).required("Please provide Facility Category") 
}).required(); 


export const addSupplierUserSchema = yup.object({
  first_name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters').required('Please provide First Name '),
  last_name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters').required('Please provide Last Name '),
  email: yup.string().matches( /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,'Invalid email address').required("Please provide Email"),
  password: yup.string().test(
    'password-required',
    'Please provide a Password',
    function (value) {
      const { selectedId } = this.options.context;
      return selectedId || (!!value && value.trim() !== '');
    }
  ).min(8, "Password must be at least 8 characters long"),
  phone: yup.string().matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits").required("Please provide Phone Number"),
  address: yup.string().required("Please provide Address"),
  city_id: yup.string().required("Please provide City"),
  pin_code: yup.string().matches(/^[0-9]{6}$/, "Pincode must be exactly 6 digits").required("Please provide Pincode"),
  zone_id: yup.string().required("Please provide Zone"),
  state_id: yup.string().required("Please provide State"),
  suppliercompany_id: yup.string().required("Please provide Company"),
}).required();


// export const addSupplierUserSchema = yup.object({
//   first_name: yup.string().required("Please provide First Name"),
//   last_name: yup.string().required("Please provide Last Name"),
//   email: yup.string().email("Invalid email address").required("Please provide Email"),
//   password: yup.string().required("Please provide a Password").min(8, "Password must be at least 8 characters long"),
//   phone: yup.string().matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits").required("Please provide Phone Number"),
//   address: yup.string().required("Please provide Address"),
//   city_id: yup.string().required("Please provide City"),
//   pin_code: yup.string().matches(/^[0-9]{6}$/, "Pincode must be exactly 6 digits").required("Please provide Pincode"),
//   zone_id: yup.string().required("Please provide Zone"),
//   state_id: yup.string().required("Please provide State"),
//   department_id: yup.string().required("Please provide Department"),
//   //role: yup.string().required("Please provide Role"), 
// }).required();

export const addUserRoleSchema = yup.object({
  usertype_name: yup.string().required("Please provide User Type"),
  name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters').required("Please provide Role Name"), 
}).required();

export const AddDepartmentSchema = yup.object({
  department_name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters').test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
    return value === value?.trim();
  }).required("Please provide Department Name"), 
}).required();

export const addOrganizationSchema = yup.object({
  name: yup.string()
    .matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters')
    .test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
      return value === value?.trim();
    })
    .required("Please provide Organization Name"),
  is_direct: yup.string().required("Please provide Type of Organization"),
}).required();


export const categorySchema = yup.object({
    category: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters').test('no-leading-trailing-spaces', 'No leading or trailing spaces allowed', (value) => {
      return value === value?.trim();
    }).required("Category Name is required"),
    snap: yup.mixed().required("Category picture is required")
});

export const addInventorySchema = yup.object().shape({
  supplier_company_id: yup.lazy((value, { parent }) =>
    parent.supplier_id ? yup.string() : yup.string().required('Supplier is required')
  ),
  supplier_id: yup.string(),
  category_id: yup.string().required('Category is required'),
  kia_specification: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters').required('Supplier Specification is required'),
  unit_of_measurement: yup.string().required('Unit of Measurement is required'),
  quantity: yup.number().required('Quantity is required').positive().integer(),
  supplier_price: yup.number().required('Supplier Price is required').positive(),
  vendor_price: yup.number().required('Vendor Price is required').positive(),
  product_description: yup.string().required('Product Description is required'),
});

export const addRemarkSchema = yup.object().shape({
  remark: yup
  .string()
  .matches(/^[a-zA-Z0-9\s,\.]+$/, 'Only letters (a-z, A-Z), numbers (0-9), spaces, commas, and periods are allowed')
  .required('Please provide a comment'),
});

export const updateProfileSchema = yup.object({
  first_name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters').required('Please provide First Name '),
  last_name: yup.string().matches(/^[a-zA-Z\s]+$/, 'Only letters (a-z, A-Z) and spaces are allowed, no special characters').required('Please provide Last Name '),
  phone: yup.string().matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits").required("Please provide Phone Number"),
}).required();