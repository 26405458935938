import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button, Card, FormControl, FormControlLabel, IconButton, Modal, Radio, RadioGroup, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateDealorApi } from '../../store/auth/updateDealor';

export default function DealerStatusConf({ open, setOpen }) {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [dealorStatus, setDealorStatus] = useState('')

    const handleClose = () => setOpen(false)


    const handleDealorStatusChange = (e) => {
        setDealorStatus(e.target.value)
    }

    const handleChangeDealerStatus = async () => {
        const updateStatus = await dispatch(updateDealorApi({ dealer_status: dealorStatus }))
        if (!updateStatus?.payload?.error) {
            setOpen(false)
            window.location.reload()
            navigate("/")
        }
    }

    return (
        <>
            <Modal open={open} onClose={handleClose}>
                <Card className='modal'>
                    <Stack spacing={2}>
                        <Stack alignItems='flex-end'><IconButton onClick={handleClose}><HighlightOffIcon /></IconButton></Stack>
                        <Stack alignItems='center'>
                            <FormControl>
                                <RadioGroup onChange={handleDealorStatusChange}>
                                    <Stack spacing={2}>
                                        <Card className='card1'><FormControlLabel value="new" control={<Radio />} label="New Dealor" /></Card>
                                        <Card className='card1'><FormControlLabel value="old" control={<Radio />} label="Existing Dealer" /></Card>
                                    </Stack>
                                </RadioGroup>
                            </FormControl>
                        </Stack>
                        <Stack direction='row' spacing={2} justifyContent='center'>
                            <Button variant='contained' onClick={handleChangeDealerStatus} disabled={!dealorStatus}>Continue</Button>
                        </Stack>
                    </Stack>
                </Card>
            </Modal>
        </>
    )
}

